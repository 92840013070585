<template>
  <div class="fill-height fill-width" style="position: relative;">
    <Loading v-if="loading" />
    <div v-else-if="!!currentPackage" class="fill-height fill-width">
      <Map :mapRef.sync="mapRef" />
      <SearchResults :mapRef="mapRef" />
      <FeatureDetails />
      <SearchSelector :mapRef="mapRef" />
      <HelpDialog></HelpDialog>
      <UploadExternalLayerDialog></UploadExternalLayerDialog>
    </div>
  </div>
</template>
<script>
import Map from "@/components/Map/Map";
import FeatureDetails from "@/components/Features/FeatureDetails";
import SearchResults from "@/components/Search/SearchResults";
import SearchSelector from "@/components/Search/SearchSelector";
import Loading from "@/components/General/Loading";
import HelpDialog from "@/components/Help/HelpDialog";
import UploadExternalLayerDialog from "@/components/ExternalLayers/UploadExternalLayerDialog";

export default {
  components: {
    Map,
    SearchResults,
    FeatureDetails,
    Loading,
    HelpDialog,
    SearchSelector,
    UploadExternalLayerDialog
  },
  data() {
    return {
      mapRef: null,
    };
  },
  computed: {
    package_id() {
      return this.$route.params.id;
    },
    loading() {
      return this.$store.state.packages.loadingPackage;
    },
    currentPackage() {
      return this.$store.state.packages.currentPackage;
    },
    packageSettings() {
      return this.$store.state.packages.currentPackage
        ? this.$store.state.packages.currentPackage.settings
        : null;
    },
  },
  watch: {
    package_id() {
      this.initializePackage();
    },
  },
  mounted() {
    this.initializePackage();
    this.$store.dispatch("settings/GET_SETTINGS_INFO");
  },
  methods: {
    initializePackage() {
      this.$store.dispatch("packages/GET_PACKAGE", this.package_id).then(() => {
        if (this.packageSettings) {
          this.setTheme(this.packageSettings.color);
        }
      });
      this.$store.dispatch("ui/SET_CURRENT_PACKAGE_ID", this.package_id);
    },
    setTheme(color) {
      let theme = {
        dark: {
          primary: color,
        },
        light: {
          primary: color,
        },
      };
      this.$setTheme(theme);
    },
  },
};
</script>