<template>
  <div v-if="!!item && item.value" class="mb-1">
      <p class="text-subtitle-2 text-left pb-0 mb-0">{{item.name}}</p>
      <p class="text-justify pb-0 mb-0" style="font-size:0.75rem" v-html="item.value"></p>
  </div>
</template>
<script>
export default {
  props: ["item"],
};
</script>