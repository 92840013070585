export default class MouseCoordinatesControl {

    constructor(options) {
        this.options = options;
    }

    onAdd(map) {
        this._map = map;
        this._initControl();
        this._registerEvents();
        return this._container;
    }

    _initControl() {
        this._container = document.createElement("div");
        this._container.className =
            "maplibregl-ctrl mapboxgl-ctrl maplibregl-coordinates";
        this._container.title = this._map._getUIString(`MouseCoordinates.Title`);

        // Coords presentation div
        this._coordsDiv = document.createElement("div");
        this._container.appendChild(this._coordsDiv);

        // Copy value input
        this._coordsInput = document.createElement("input");
        this._coordsInput.type = 'hidden';
        this._container.appendChild(this._coordsInput);
    }

    _registerEvents() {
        if (this._container)
            this._container.addEventListener('click', this.copyToClipboard.bind(this));
        if (this._map) {
            this._map.on("load", () => {
                this.updateCoords();
            });
            this._map.on("move", () => {
                this.updateCoords();
            });
            this._map.on('mousemove', this.updateCoords.bind(this));
        }
    }

    copyToClipboard() {
        if (this._coordsInput) {
            // Copy the coordinates value
            navigator.clipboard.writeText(this._coordsInput.value);
        }
    }

    updateCoords(e) {
        if (!this._coordsDiv) {
            return;
        }
        let lat, lng;
        if (e && e.lngLat) {
            // The event object (e) contains information like the
            // coordinates of the point on the map that was clicked.
            lat = e.lngLat.lat;
            lng = e.lngLat.lng;
        } else {
            // Assume map center
            let center = this._map.getCenter();
            lat = center.lat;
            lng = center.lng;
        }
        lat = lat.toFixed(6);
        lng = lng.toFixed(6);
        this._coordsDiv.innerHTML = `Lat: <span>${lat}</span> | Lng: <span>${lng}</span> [EPSG:4326 (WGS84)`;
        this._coordsInput.value = `${lat},${lng}`;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}