<template>
  <div v-if="!isSearchResultsOpen && !isFeatureDetailsOpen" class="search-selector">
    <v-card class="pa-2" :class="{ 'mt-2 mx-2': $vuetify.breakpoint.smAndDown }" elevation="6">
      <v-btn-toggle class="d-flex" dense v-model="searchMode" mandatory>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="flex-grow-1" small value="everywhere">
              <v-icon left small>mdi-database-search-outline</v-icon>
              <span class="button" style="font-size: 0.8em">
                {{ $t('global.search_everywhere') }}
              </span>
            </v-btn>
          </template>
          <span>{{ $t('global.search_everywhere_on_database_explanation') }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="flex-grow-1" small value="visible-area">
              <v-icon left small>mdi-fullscreen</v-icon>
              <span class="button" style="font-size: 0.8em">
                {{ $t('global.visible_area') }}
              </span>
            </v-btn>
          </template>
          <span>{{ $t('global.search_visible_area_on_database_explanation') }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="flex-grow-1" small value="toponymy">
              <v-icon left small>mdi-crosshairs</v-icon>
              <span class="button" style="font-size: 0.8em">{{ $t('global.toponymy') }}</span>
            </v-btn>
          </template>
          <span>{{ $t('global.toponymy_explanation') }}</span>
        </v-tooltip>
      </v-btn-toggle>
      <Searchbar v-show="searchMode == 'everywhere' || searchMode == 'visible-area'" class="my-2" :mapRef="mapRef" />
      <SearchToponymy v-show="searchMode == 'toponymy'" class="mt-2" />
    </v-card>
  </div>
</template>
<script>
import Searchbar from "@/components/Search/Searchbar";
import SearchToponymy from "@/components/Search/SearchToponymy";

export default {
  props: ["mapRef"],
  components: {
    Searchbar,
    SearchToponymy,
  },
  computed: {
    searchMode: {
      get() {
        return this.$store.state.ui.searchMode;
      },
      set(val) {
        this.$store.dispatch("ui/SET_SEARCH_MODE", val);
      }
    },
    package_id() {
      return this.$route.params.id;
    },
    isSearchResultsOpen() {
      return this.$store.state.ui.searchOpen;
    },
    isFeatureDetailsOpen() {
      return this.$store.state.ui.featureDetailsOpen;
    },
  },
};
</script>
<style scoped>
.search-selector {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

@media only screen and (max-width: 800px) {
  .search-selector {
    width: 100%;
    top: 0;
    right: 0;
  }
}
</style>
