<template>
  <video width="50%" controls class="faq-video">
    <source :src="url" type="video/mp4" />
    {{ $t('global.video_not_supported') }}
  </video>
</template>
<script>
export default {
  props: ["url"],
};
</script>
<style scoped>
.faq-video {
  width: 90%;
  max-width: 800px;
}
</style>
