import CustomMapMarkerSVG from "@/helpers/CustomMapMarkers/CustomMapMarkerSVG";
import { svgUtils } from "@/helpers/svg-utils";

const SELECTED_STROKE_COLOR = "#F48C2F";
const SELECTED_STROKE_OPACITY = 0.8;
const SELECTED_STROKE_WIDTH = 9;
const HOVERED_STROKE_WIDTH = 5;
const HOVERED_STROKE_COLOR = "#6d8717";
const HOVERED_FILL_OPACITY = 0.7;

async function buildPointLayers(id, spec, sourceId, map) {
  let pointGeomClusterLayerId = `l_p_c_${id}`;
  let pointGeomClusterTextLayerId = `l_p_c_t${id}`;
  let pointLayerId = `l_p_${id}`;

  if (map) {
    let stylesToProcess = [];
    stylesToProcess.push(spec.style); // Base style

    if (spec.style_classes) {
      let styleClasses = spec.style_classes.map((sc) => {
        sc.style._style_class_id = sc.id;
        return sc.style;
      });
      stylesToProcess = stylesToProcess.concat(styleClasses);
    }
    for (const style of stylesToProcess) {
      let rawSvgText = null;
      if (!style.custom_image) {
        if (style.iconShape === "marker" || style.iconShape === "circle") {
          let iconFromStyle = style.icon;
          let icon = iconFromStyle.split(" ")[1].replace("fa-", "");
          rawSvgText = await CustomMapMarkerSVG.fetchRawSvgFaIcon(
            `FontAwesome/${icon}.svg`
          );
        }
      }
      let { hoveredSVG, unhoveredSVG, selectedSVG } =
        CustomMapMarkerSVG.buildSvg(style, rawSvgText);
      let styleMapSuffix = style._style_class_id
        ? `_${style._style_class_id}`
        : "";
      let imagesToLoad = [
        {
          id: `${id}_img_hovered` + styleMapSuffix,
          img: hoveredSVG,
        },
        {
          id: `${id}_img_unhovered` + styleMapSuffix,
          img: unhoveredSVG,
        },
        {
          id: `${id}_img_selected` + styleMapSuffix,
          img: selectedSVG,
        },
      ];
      imagesToLoad.forEach((item) => {
        svgUtils.svgToPng(item.img, (imgData) => {
          map.loadImage(imgData, (error, image) => {
            if (error) throw error;
            map.hasImage(item.id)
              ? map.updateImage(item.id, image)
              : map.addImage(item.id, image);
          });
        });
      });
    }
  }
  return [
    {
      id: pointLayerId,
      layer: {
        id: pointLayerId,
        type: "symbol",
        source: sourceId,
        filter: ["!", ["has", "point_count"]],
        layout: {
          "icon-image": ["get", "_svgImage"],
          "icon-size": 0.8,
          "icon-allow-overlap": true,
          "icon-anchor": spec.style.iconShape == "marker" ? "bottom" : "center",
        },
      },
    },
    {
      id: pointGeomClusterLayerId,
      layer: {
        id: pointGeomClusterLayerId,
        type: "circle",
        source: sourceId,
        filter: ["has", "point_count"],
        layout: {},
        paint: {
          "circle-stroke-color": "#ffffff",
          "circle-stroke-width": 2,
          "circle-opacity": 0.9,
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#0096c7",
            100,
            "#0077b6",
            750,
            "#023e8a",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            15,
            100,
            25,
            750,
            30,
          ],
        },
      },
    },
    {
      id: pointGeomClusterTextLayerId,
      layer: {
        id: pointGeomClusterTextLayerId,
        type: "symbol",
        source: sourceId,
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["Klokantech Noto Sans Bold"],
          "text-size": 12,
        },
        paint: {
          "text-color": "#ffffff",
        },
      },
    },
  ];
}

async function buildPointClusterLayers(id, spec, sourceId, map) {
  return await buildPointLayers(id, spec, sourceId, map);
}

function buildLineStringLayers(id, spec, sourceId) {
  let lineLayerId = `l_l_o_${id}`;
  let layerSpec = {
    id: lineLayerId,
    type: "line",
    source: sourceId,
    layout: {},
    paint: {
      "line-width": parseFloat(spec.style.weight),
      "line-opacity": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        SELECTED_STROKE_OPACITY, //hovered,
        ["boolean", ["feature-state", "selected"], false],
        HOVERED_FILL_OPACITY,
        ["get", "opacity"], //unhovered
      ],
      "line-color": [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        SELECTED_STROKE_COLOR,
        ["boolean", ["feature-state", "hover"], false],
        HOVERED_STROKE_COLOR,
        ["get", "color"],
      ],
    },
  };
  if (spec.style.border == "dotted") {
    Object.assign(layerSpec, {
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      "line-dasharray": [0.2, 2],
    });
  } else if (spec.style.border == "dashed") {
    Object.assign(layerSpec, {
      "line-dasharray": [5, 5],
    });
  }
  return [
    {
      id: lineLayerId,
      layer: layerSpec,
    },
  ];
}

function buildPolygonLayers(id, _, sourceId) {
  let outlineLayerId = `l_p_o_${id}`;
  let fillLayerId = `l_p_f_${id}`;
  return [
    {
      id: outlineLayerId,
      layer: {
        id: outlineLayerId,
        source: sourceId,
        type: "line",
        layout: {
          "line-sort-key": 5,
        },
        paint: {
          "line-width": [
            "case",
            ["boolean", ["feature-state", "selected"], false],
            SELECTED_STROKE_WIDTH,
            ["boolean", ["feature-state", "hover"], false],
            HOVERED_STROKE_WIDTH,
            ["get", "weight"],
          ],
          "line-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            SELECTED_STROKE_OPACITY,
            ["boolean", ["feature-state", "selected"], false],
            SELECTED_STROKE_OPACITY,
            ["get", "opacity"],
          ],
          "line-color": [
            "case",
            ["boolean", ["feature-state", "selected"], false],
            SELECTED_STROKE_COLOR,
            ["boolean", ["feature-state", "hover"], false],
            HOVERED_STROKE_COLOR,
            ["get", "color"],
          ],
        },
      },
    },
    {
      id: fillLayerId,
      layer: {
        id: fillLayerId,
        type: "fill",
        source: sourceId,
        layout: {
          "fill-sort-key": 6,
        },
        paint: {
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0,
            ["boolean", ["feature-state", "selected"], false],
            0,
            ["get", "fillOpacity"],
          ],
          "fill-color": ["get", "fillColor"],
        },
      },
    },
  ];
}

async function buildGeometryCollectionLayers(id, spec, sourceId, map) {
  // For GeometryCollections (aka Networks) we need a little twist on the style:
  let pointsSpec = {
    ...spec,
    style: {
      custom_image: spec.style.custom_image,
      border: spec.style.border_node,
      color: spec.style.color_node,
      fillColor: spec.style.fillColor_node,
      fillOpacity: spec.style.fillOpacity_node,
      icon: spec.style.icon_node,
      iconShape: spec.style.iconShape_node,
      iconColor: spec.style.iconColor_node,
      opacity: spec.style.opacity_node,
      radius: spec.style.radius_node,
      weight: spec.style.weight_node,
    },
  };

  let linesSpec = {
    ...spec,
    style: {
      border: spec.style.border_link,
      color: spec.style.color_link,
      opacity: spec.style.opacity_link,
      weight: spec.style.weight_link,
    },
  };

  let pointLayers = await buildPointLayers(id, pointsSpec, sourceId, map);
  let linestringLayers = buildLineStringLayers(id, linesSpec, sourceId);
  return linestringLayers.concat(pointLayers);
}

function buildWmsLayers(id, _, sourceId) {
  let wmsLayerId = `l_wms_${id}`;
  return [
    {
      id: wmsLayerId,
      layer: {
        id: wmsLayerId,
        type: "raster",
        source: sourceId,
      },
    },
  ];
}

export {
  buildPointLayers,
  buildPointClusterLayers,
  buildLineStringLayers,
  buildPolygonLayers,
  buildWmsLayers,
  buildGeometryCollectionLayers,
};
