//How to : https://learn.microsoft.com/en-us/bingmaps/rest-services/imagery/get-imagery-metadata

var attr = `
<div style="vertical-align:middle;display:inline-block;">
     Map data &copy ${new Date().getFullYear()} Google
</div>
`;

export const googleMapSourcesHelper = {
    getSources
};

function getSources() {
    let google_road = {
        version: 8,
        sources: {
            "google-road-tiles": {
                type: "raster",
                tiles: ["https://mt.google.com/vt/lyrs=m&hl=pt&x={x}&y={y}&z={z}&s=Ga"],
                tileSize: 256,
                attribution: attr,
                minzoom: 1,
                maxzoom: 19,
            },
        },
        glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",

        layers: [
            {
                id: "google-road-tiles-layer",
                type: "raster",
                source: "google-road-tiles",
            },
        ],
    };
    let google_hybrid = {
        version: 8,
        sources: {
            "google-hybrid-tiles": {
                type: "raster",
                tiles: ["https://mt.google.com/vt/lyrs=s&hl=pt&x={x}&y={y}&z={z}&s=Ga"],
                tileSize: 256,
                attribution: attr,
                minzoom: 1,
                maxzoom: 19,
            },
        },
        glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",

        layers: [
            {
                id: "google-hybrid-tiles-layers",
                type: "raster",
                source: "google-hybrid-tiles",
            },
        ],
    };

    let response = {};
    response.googleRoads = google_road;
    response.googleHybrid = google_hybrid;
    return response;
}