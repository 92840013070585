export default class NumericScaleControl {

    constructor(options) {
        this.options = options;
    }

    onAdd(map) {
        this._map = map;
        this._currentScale = 0;
        // Add the default scale
        this._availableScales = [
            {
                value: null,
                text: "«auto»",
                zoom: null,
              },
        ];
        let scales = this.options.scales ? this.options.scales : [];
        this._availableScales = this._availableScales.concat(scales);

        this._container = document.createElement("div");
        this._container.className =
            "maplibregl-ctrl mapboxgl-ctrl maplibregl-scale";
        this._initScaleSelectorComponent();
        this._map.on("move", this._onMove.bind(this));
        this._map.on("load", () => {
            this._onMove();
        });
        this._onMove();
        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    _initScaleSelectorComponent() {
        if (this._container) {
            // Ruler used to calculate how much map is 1cm :)
            this._ruler = document.createElement("div");
            this._ruler.className = "maplibregl-scale-ruler";
            this._container.appendChild(this._ruler);

            // Title
            if (this.options.title) {
                let title = document.createElement("span");
                title.innerText = this.options.title;
                this._container.appendChild(title);
            }

            // Scale selector to jump to a fixed scale
            this._scaleSelector = document.createElement("select");
            this._scaleSelector.addEventListener(
                "change",
                this._onScaleChange.bind(this)
            );
            this._availableScales.forEach((scale) => {
                let scaleElem = document.createElement("option");
                scaleElem.innerText = scale.text;
                scaleElem.setAttribute("value", scale.value);
                this._scaleSelector.appendChild(scaleElem);
            });
            this._container.appendChild(this._scaleSelector);
        }
    }

    _onMove() {
        this.updateScale();
    }

    _onScaleChange() {
        if (this._scaleSelector) {
            let targetScale = this._scaleSelector.value;
            let scale = this._availableScales.find((scale) => {
                return scale.value == targetScale;
            });
            if (scale && scale.zoom) {
                this._map.zoomTo(scale.zoom);
            }
        }
    }

    getScaleValue() {
        if (this._scaleSelector && (this._scaleSelector.value && this._scaleSelector.value !== "null")) {
            return this._scaleSelector.value;
        }
        if (this._currentScale) {
            return this._currentScale.toFixed(0)*100;
        }
        return null;
    }

    updateScale() {
        // A horizontal scale is imagined to be present at center of the map
        // container with maximum length (Default) as 1cm.
        // Using spherical law of cosines approximation, the real distance is
        // found between the two coordinates.
        const rulerRect = this._ruler.getBoundingClientRect();
        const maxWidth = rulerRect.width; //1cm is and will always be 1cm
        const y = this._map._container.clientHeight / 2;
        const left = this._map.unproject([0, y]);
        const right = this._map.unproject([maxWidth, y]);
        this._currentScale = left.distanceTo(right);
        if (this._scaleSelector) {
            let autoOption = this._scaleSelector.options.item(0);
            autoOption.innerHTML = ` 1 : ${this._currentScale.toFixed(0)*100}`;
        }
        let currentZoom = this._map.getZoom();
        let scaleToSelect = this._availableScales.find((scale) => {
            return scale.zoom == currentZoom;
        });
        if (scaleToSelect) {
            this._scaleSelector.value = scaleToSelect.value;
        } else {
            this._scaleSelector.value = null;
        }
    }
}