import { bingMapSourcesHelper } from "@/helpers/bing-map-sources-helper";
import { googleMapSourcesHelper } from "@/helpers/google-map-sources-helper";

export const AVAILABLE_BASE_LAYERS = {
  google_road: googleMapSourcesHelper.getSources().googleRoads,
  google_hybrid: googleMapSourcesHelper.getSources().googleHybrid,
  osm: {
    version: 8,
    sources: {
      osm: {
        type: "raster",
        tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
        tileSize: 256,
        attribution: "&copy; OpenStreetMap Contributors",
        maxzoom: 19,
      },
    },
    glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
    layers: [
      {
        id: "osm",
        type: "raster",
        source: "osm", // This must match the source key above
      },
    ],
  },
  bing: bingMapSourcesHelper.getSources().bingRoads,
  bing_hybrid: bingMapSourcesHelper.getSources().bingHybrid,
  whitepane: {
    version: 8,
    sources: {},
    glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
    layers: [
      {
        id: "background",
        type: "background",
        paint: {
          "background-color": "white",
        },
      },
    ],
  },
};
