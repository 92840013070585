<template>
  <div>
    <v-card v-if="shouldShow" class="pa-0 ma-0" flat>
      <v-card-text class="pb-4">
        <Field v-for="item in item.attributes" :key="item.id" :item="item" />
      </v-card-text>
    </v-card>
    <v-card flat v-else>
      <v-card-text class="pt-4" >
        <div class="subtitle-2">(sem informação)</div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Field from "@/components/Features/Field";
export default {
  components: {
    Field,
  },
  props: ["item"],
  computed: {
    shouldShow() {
      return (
        !!this.item &&
        // regular attribute group
        this.item.attributes &&
        this.item.attributes.length > 0 &&
        this.item.attributes.filter((f) => f.value && f.value !== "").length > 0
      );
    },
  },
};
</script>