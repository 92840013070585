
export default class PrintMapControl {
  constructor(options) {
    this.options = options;
  }

  onAdd(map) {
    this._map = map;
    this._initControl();
    this._registerEvents();
    return this._container;
  }

  _initControl() {
    this._container = document.createElement("div");
    this._container.className =
      "maplibregl-ctrl mapboxgl-ctrl maplibregl-opendialog maplibregl-ctrl-group mapboxgl-ctrl-group";
    this._container.title = this._map._getUIString(`PrintMap.Title`);
    this._printMapBtn = document.createElement("button");
    this._printMapBtn.classList = "maplibregl-fitzoom-button";
    this._printMapBtn.type = "button";
    this._printMapBtn.innerHTML = `
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z"></path>
        </svg>
        `;
    this._container.appendChild(this._printMapBtn);
  }

  _registerEvents() {
    if (this._printMapBtn) {
      this._printMapBtn.addEventListener("click", this.handlePrint.bind(this));
    }
  }

  handlePrint() {
    if (!this._map) {
      return;
    }
    this._map.fire("print_control.click");
  }

}
