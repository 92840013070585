//How to : https://learn.microsoft.com/en-us/bingmaps/rest-services/imagery/get-imagery-metadata

var attr =
    `
<div>
    <svg style="width:24px;height:24px;vertical-align:middle;display:inline-block;" viewBox="0 0 24 24">
        <path fill="#737373" d="M5,3V19L8.72,21L18,15.82V11.73H18L9.77,8.95L11.38,12.84L13.94,14L8.7,16.92V4.27L5,3" />
    </svg>
    <span style="vertical-align:middle;display:inline-block;">
        Bing Maps
    </span> 
    |
    <div style="vertical-align:middle;display:inline-block;">
        &copy Microsoft and its suppliers
        ${new Date().getFullYear()}
    </div>
</div>
`;

export const bingMapSourcesHelper = {
    getSources
};

function getSources() {
    let bingRoads = {
        version: 8,
        sources: {
            "bing-road-tiles": {
                type: "raster",
                tiles: [
                    "https://t0.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=pt-PT&it=G,L&shading=hill&og=2023&n=z",
                    "https://t1.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=pt-PT&it=G,L&shading=hill&og=2023&n=z",
                    "https://t2.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=pt-PT&it=G,L&shading=hill&og=2023&n=z",
                    "https://t3.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=pt-PT&it=G,L&shading=hill&og=2023&n=z",
                ],
                tileSize: 256,
                attribution: attr,
                minzoom: 1,
                maxzoom: 19,
            },
        },
        glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",

        layers: [
            {
                id: "bing-road-tiles_layer",
                type: "raster",
                source: "bing-road-tiles",

            },
        ],
    };
    let bingHybrid = {
        version: 8,
        sources: {
            "bing-hybrid-tiles": {
                type: "raster",
                tiles: [
                    "https://t0.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=pt-PT&it=A,G,L&og=2023&n=z",
                    "https://t1.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=pt-PT&it=A,G,L&og=2023&n=z",
                    "https://t2.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=pt-PT&it=A,G,L&og=2023&n=z",
                    "https://t3.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=pt-PT&it=A,G,L&og=2023&n=z",
                ],
                tileSize: 256,
                attribution: attr,
                minzoom: 1,
                maxzoom: 19,
            },
        },
        glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",

        layers: [
            {
                id: "bing-hybrid-tiles_layer",
                type: "raster",
                source: "bing-hybrid-tiles",

            },
        ],
    }
    let response = {};
    response.bingRoads = bingRoads;
    response.bingHybrid = bingHybrid;
    return response;
}