<template>
  <div :style="'width:' + fieldWidth">
    <v-card tile flat>
      <v-radio-group v-model="selectedMode" row hide-details dense class="row-radio-group mt-0"
        active-class="row-radio-group-active">
        <v-radio label="Coordenadas" value="by-coordinates"></v-radio>
        <v-radio label="Código postal" value="by-postal-code"></v-radio>
        <v-radio label="Ruas" value="by-address"></v-radio>
      </v-radio-group>
      <v-expand-transition>
        <div v-show="shouldShow">
          <ByCoordinates v-show="selectedMode == 'by-coordinates'"></ByCoordinates>
          <ByPostalCode v-show="selectedMode == 'by-postal-code'"></ByPostalCode>
          <ByAddress v-show="selectedMode == 'by-address'"></ByAddress>
        </div>
      </v-expand-transition>
    </v-card>
    <v-card-actions class="d-flex flex-column justify-center ma-0 pa-0">
      <v-divider></v-divider>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="shouldShow = !shouldShow" small>
            <v-icon>{{ shouldShow ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </template>
        <span v-if="!shouldShow">{{ $t('global.show') }}</span>
        <span v-if="!!shouldShow">{{ $t('global.hide') }}</span>
      </v-tooltip>
    </v-card-actions>
  </div>
</template>
<script>
import ByCoordinates from '@/components/Search/Toponymy/ByCoordinates';
import ByPostalCode from '@/components/Search/Toponymy/ByPostalCode';
import ByAddress from '@/components/Search/Toponymy/ByAddress';

export default {
  components: {
    ByCoordinates,
    ByPostalCode,
    ByAddress,
  },
  data() {
    return {
      selectedMode: 'by-coordinates',
      shouldShow: true,
    };
  },
  computed: {
    fieldWidth() {
      return this.$vuetify.breakpoint.width <= 800 ? "100%" : "450px";
    },
  }
};
</script>
<style>
.row-radio-group .v-input--radio-group__input {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: center;
}

.row-radio-group .v-label {
  font-size: 0.8rem !important;
}

.row-radio-group-active {
  font-weight: bold;
}
</style>
