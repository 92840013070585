<template>
  <div class="layer-switcher" v-if="!!currentPackage">
    <v-menu
      open-on-hover
      close-on-content-click
      close-delay="200"
      right
      offset-x
      :nudge-right="10"
      content-class="elevation-0"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-card tile v-bind="attrs" v-on="on">
          <v-img
            width="100"
            height="100"
            class="white--text align-end"
            :src="getNextBaseLayerImagePath()"
          >
          </v-img>
          <v-card-actions class="pa-0">
            <p
              class="pa-0 ma-0 text-center"
              style="
                width: 100%;
                font-size: 10px;
                font-weight: bold;
                text-transform: uppercase;
              "
            >
              {{ getNextBaseLayerName(selectedOption) }}
            </p>
          </v-card-actions>
        </v-card>
      </template>
      <div class="switcher-options">
        <div class="d-flex">
          <div v-for="option in switcherOptions" :key="option" class="mx-1">
            <BaseLayerSwitcherOption
              :option="option"
              :selected="selectedOption"
              :layerName="getNextBaseLayerName(option)"
              @optionSelected="handleOptionSelected"
            />
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>
<script>
import BaseLayerSwitcherOption from "@/components/Map/BaseLayerSwitcherOption";

export default {
  props: ["value"],
  components: { BaseLayerSwitcherOption },
  data: function () {
    return {
      switcherOptions: [],
      switcherOptionDefault: "osm",
      selectedOption: "osm",
    };
  },
  computed: {
    currentPackage() {
      return this.$store.state.packages.currentPackage;
    },
  },
  watch: {
    currentPackage() {
      this.initialize();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.currentPackage) {
        this.getDefaultOption();
        this.buildSwitcherOptions();
      } 
    },
    buildSwitcherOptions() {
      let settings = this.currentPackage.settings;
      if (settings.map_ison_osm === "true") this.switcherOptions.push("osm");
      if (settings.map_ison_bingmap === "true")
        this.switcherOptions.push("bing");
      if (settings.map_ison_binghyb === "true")
        this.switcherOptions.push("bing_hybrid");
      if (settings.map_ison_googlemap === "true")
        this.switcherOptions.push("google_road");
      if (settings.map_ison_googlehyb === "true")
        this.switcherOptions.push("google_hybrid");
      if (settings.map_ison_whitepane === "true")
        this.switcherOptions.push("whitepane");
    },
    getDefaultOption() {
      let settings = this.currentPackage.settings;
      if (settings.map_isdefault_osm === "true")
        this.switcherOptionDefault = "osm";
      if (settings.map_isdefault_binghyb === "true")
        this.switcherOptionDefault = "bing_hybrid";
      if (settings.map_isdefault_bingmap === "true")
        this.switcherOptionDefault = "bing";
      if (settings.map_isdefault_googlehyb === "true")
        this.switcherOptionDefault = "google_hybrid";
      if (settings.map_isdefault_googlemap === "true")
        this.switcherOptionDefault = "google_road";
      if (settings.map_isdefault_whitepane === "true")
        this.switcherOptionDefault = "whitepane";

      this.selectedOption = this.switcherOptionDefault;
      this.$emit("input", this.selectedOption);
      this.$emit("change", this.selectedOption);
    },
    handleOptionSelected(val) {
      this.selectedOption = val;
      this.$emit("input", val);
      this.$emit("change", val);
    },
    getNextBaseLayerImagePath() {
      return require("@/assets/basemaps/" + this.selectedOption + ".png");
    },
    getNextBaseLayerName(key) {
      switch (key) {
        case "osm":
          return "OSM";
        case "bing":
          return "Bing Mapa";
        case "google_hybrid":
          return "Google Satélite";
        case "google_road":
          return "Google Mapa";
        case "bing_hybrid":
          return "Bing Híbrido";
        case "whitepane":
          return "Tela Branca";
        default:
          return "Mapa";
      }
    },
  },
};
</script>
<style scoped>
.layer-switcher {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 100px;
}
</style>