<template>
    <v-dialog v-model="isOpen" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card height="100%" class="print-card">
            <v-toolbar dark color="primary" dense class="no-print">
                <v-toolbar-title><v-icon left>mdi-printer-outline</v-icon>{{ $t("global.print_map") }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon dark @click="isOpen = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="overflow-y-auto print-card-text" style="position: relative; height:calc(100% - 48px);">
                <Loading v-if="!image"></Loading>
                <template v-else>
                    <div class="d-flex flex-column align-center pa-4">
                        <div class="d-flex flex-row mb-4 no-print">
                            <v-btn color="primary" class="mx-2" @click.stop="browserPrint"><v-icon
                                    left>mdi-printer</v-icon>Imprimir</v-btn>
                        </div>
                        <v-card ref="pageToPrint" class="print-a4 pa-4">
                            <div class="d-flex flex-column fill-height">
                                <div class="fill-width bordered-box">
                                    <div class="d-flex flex-row align-center pa-2">
                                        <div class="text-h6">{{ currentPackage?.name }}</div>
                                        <v-spacer></v-spacer>
                                        <div><v-img width="60" height="60" contain
                                                :src="settingsInfo.platform_project_icon"></v-img></div>
                                        <div class="ml-4 text-caption ">
                                            <div class="font-weight-bold">{{ settingsInfo.platform_company_name }}</div>
                                            <div>{{ settingsInfo.platform_company_address }}</div>
                                            <div>{{ settingsInfo.platform_company_postal_code }}</div>
                                            <div>
                                                <a :href="mailto">{{ settingsInfo.platform_company_email }}</a>
                                            </div>
                                            <div>
                                                <a :href="telephone">{{ settingsInfo.platform_company_telephone }}</a>
                                            </div>
                                        </div>
                                    </div>

                                </div> <!-- Header -->
                                <div class="flex-grow my-2" style="position: relative;"> <!-- Map -->
                                    <div class="compass" :style="compassRotationStyle"><svg
                                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                            version="1.1" viewBox="0 0 256 256" xml:space="preserve">

                                            <defs>
                                            </defs>
                                            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                                                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                                                <path
                                                    d="M 22.107 67.708 c -0.531 0 -1.053 -0.211 -1.438 -0.609 c -0.592 -0.612 -0.731 -1.532 -0.348 -2.292 l 22.894 -45.326 c 0.418 -0.826 1.348 -1.257 2.249 -1.044 C 46.364 18.652 47 19.457 47 20.383 v 34.709 c 0 0.778 -0.452 1.486 -1.159 1.814 L 22.948 67.522 C 22.678 67.647 22.392 67.708 22.107 67.708 z M 43 28.778 L 26.486 61.473 L 43 53.814 V 28.778 z"
                                                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                                                    transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                                <polygon points="45,20.38 67.89,65.71 45,55.09 "
                                                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                                                    transform="  matrix(1 0 0 1 0 0) " />
                                                <path
                                                    d="M 67.893 67.708 c -0.284 0 -0.571 -0.061 -0.841 -0.186 L 44.159 56.906 C 43.452 56.578 43 55.87 43 55.092 V 20.383 c 0 -0.926 0.636 -1.731 1.537 -1.946 c 0.901 -0.214 1.832 0.217 2.249 1.044 l 22.894 45.326 c 0.384 0.76 0.244 1.68 -0.348 2.292 C 68.946 67.497 68.424 67.708 67.893 67.708 z M 47 53.814 l 16.514 7.658 L 47 28.778 V 53.814 z"
                                                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                                                    transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                            </g>
                                        </svg></div>
                                    <div class="text-right text-caption coord-text">{{
                                        northEastCoord }}<v-icon small>mdi-arrow-down-right</v-icon></div>
                                    <v-img :src="image" contain width="100%">
                                        <template v-slot:placeholder>
                                            <v-skeleton-loader type="image"></v-skeleton-loader>
                                        </template>
                                    </v-img>
                                    <div class="text-left text-caption coord-text"><v-icon small>mdi-arrow-up-left</v-icon>{{
                                        southWestCoord }}</div>
                                </div>
                                <div class="fill-width bordered-box pa-2">
                                    <div class="d-flex flex-row align-center">
                                        <div class="text-body-1 font-weight-black text-uppercase ma-0">{{
                                            $t('global.scale') }}</div>
                                        <div class="ml-2 text-body-2">1 : {{ metadata.scale }}</div>
                                    </div>
                                </div>
                                <div v-if="selectedLayers.length > 0" class="fill-width bordered-box pa-2 mt-2">
                                    <!-- Legend -->
                                    <div class="text-body-1 font-weight-black text-uppercase ma-0">{{ $t('global.legend')
                                    }}</div>
                                    <div class="d-flex flex-row flex-wrap fill-width mt-2">
                                        <div v-for="(item, index) in selectedLayers" :key="index">
                                            <div class="d-flex flex-row flex-wrap mr-4 mb-2 align-center">
                                                <template v-if="!hasStyleMapsApplied(item)">
                                                    <div class="mr-1 text-body-2 font-weight-bold">{{
                                                        item.layer.friendly_name }}</div>
                                                    <v-card flat outlined height="25" width="25">
                                                        <StylePreview :layertype="item.layer.layertype.geometry_type"
                                                            :layerstyle="item.layer.style"></StylePreview>
                                                    </v-card>
                                                </template>
                                                <template v-else>
                                                    <div class="text-body-2 font-weight-bold mr-1">{{
                                                        item.layer.friendly_name }}</div>
                                                    <div v-for="sc in item.layerdata.style_classes" :key="sc.id"
                                                        class="d-flex flex-row flex-wrap align-center mr-2">
                                                        <div style="font-size:0.65rem">{{ sc.name }}</div>
                                                        <v-card flat outlined height="25" width="25" class="ml-1 ">
                                                            <StylePreview :layertype="item.layer.layertype.geometry_type"
                                                                :layerstyle="sc.style" />
                                                        </v-card>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-top: auto;" class="fill-width"> <!-- Footer -->
                                    <v-divider class="py-1"></v-divider>
                                    <div class="d-flex flex-row text-caption font-italic">
                                        <div>Copyright © {{ currentYear }} <span class="font-weight-bold">{{
                                            settingsInfo.platform_project_title }}</span>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <div class="text-lowercase">{{ $t('global.printed_in') }} <span
                                                class="font-weight-bold">{{ currentDate }}</span></div>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import Loading from "@/components/General/Loading";
import StylePreview from "@/components/Layers/StylePreview";
import configs from "@/helpers/configs";
import { DateTime } from "luxon";
export default {
    props: ["value", "image", "metadata"],
    components: {
        Loading,
        StylePreview,
    },
    computed: {
        isOpen: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        settingsInfo() {
            return this.$store.state.settings.settingsInfo;
        },
        telephone() {
            return `tel: ${this.settingsInfo.platform_company_telephone}`;
        },
        mailto() {
            return `mailto: ${this.settingsInfo.platform_company_email}`;
        },
        currentYear() {
            return new Date().getFullYear();
        },
        currentDate() {
            return DateTime.now().toFormat('dd/MM/yyyy HH:mm');
        },
        currentPackage() {
            return this.$store.state.packages.currentPackage;
        },
        currentPackageThumb() {
            return this.getThumb(this.currentPackage?.id);
        },
        selectedLayers() {
            return this.$store.state.layers.activeLayers;
        },
        compassRotationStyle() {
            const rotate = `rotate(${-this.metadata.bearing}deg)`;
            return { transform: rotate };
        },
        northEastCoord() {
            let coord = this.metadata.bounds.getNorthEast();
            return `${coord.lat}, ${coord.lng}`;
        },
        southWestCoord() {
            let coord = this.metadata.bounds.getSouthWest();
            return `${coord.lat}, ${coord.lng}`;
        }
    },
    methods: {
        getThumb(id) {
            return configs.getApiUrl() + `/v1/packages/${id}/thumbnail`;
        },
        hasStyleMapsApplied(item) {
            return item?.layer?.default_style_map_id && item?.layerdata;
        },
        browserPrint() {
            window.print();
        },
    },
};
</script>
<style scoped>
.print-a4 {
    height: 297mm;
    width: 210mm;
    position: relative;
}

.bordered-box {
    border: 1px solid black;
}

.coord-text {
    font-size: 0.65rem !important;
}

.compass {
    position: absolute;
    top: 30px;
    left: 10px;
    z-index: 1;
    height: 30px;
    width: 30px;
    background-color: rgba(255, 255, 255);
    border-radius: 15px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
}


@media print {
    .no-print {
        display: none !important;
    }

    .print-card {
        border: none;
        box-shadow: none !important;
        padding: 0 !important;
    }

    .print-card-text {
        overflow: hidden !important;
        padding: 0 !important;
        height: 100% !important;
    }

    .print-card-text>div {
        padding: 0 !important;
        display: block !important;
    }

    .print-a4 {
        border: none;
        box-shadow: none !important;
        padding: 5mm !important;
    }
}
</style>
<style>
@page {
    margin: 0 !important;
}

@media print {

    header,
    main,
    footer {
        display: none !important;
    }
}
</style>