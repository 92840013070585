<template>
  <v-card v-if="shouldShow" class="pa-0 ma-0" flat>
    <v-card-text class="pb-3">
      <v-dialog
        v-model="imagePreviewOpen"
        max-width="80%"
        transition="dialog-bottom-transition"
        @keydown.esc="cancelPreview"
      >
        <v-img
          :src="getImageUrl(selectedImage)"
          max-height="90vh"
          contain
          class="white lighten-2"
        >
          <template v-slot:placeholder>
            <Loading />
          </template>
        </v-img>
      </v-dialog>
      <v-row>
        <v-col
          v-for="image in images"
          :key="image.id"
          class="d-flex child-flex"
          cols="4"
        >
          <v-img
            :src="getImageUrl(image)"
            aspect-ratio="1"
            class="grey lighten-2"
            @click.stop="previewImage(image)"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card flat v-else>
    <v-card-text class="pt-4">
      <div class="subtitle-2">(sem imagens para mostrar)</div>
    </v-card-text>
  </v-card>
</template>
<script>
import Loading from "@/components/General/Loading";
export default {
  components: {
    Loading,
  },
  props: ["item", "images"],
  data() {
    return {
      imagePreviewOpen: false,
      selectedImage: null,
    };
  },
  computed: {
    package_id() {
      return this.$route.params.id;
    },
    shouldShow() {
      return !!this.item && this.images.length > 0;
    },
  },
  methods: {
    getImageUrl(image) {
      if (image) {
        return `/api/v1/packages/${this.package_id}/images/${image.id}/download`;
      }
      return null;
    },
    cancelPreview() {
      this.imagePreviewOpen = false;
      this.selectedImage = null;
    },
    previewImage(image) {
      this.selectedImage = image;
      this.imagePreviewOpen = true;
    },
  },
};
</script>
