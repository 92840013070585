<template>
    <v-file-input v-model="selectedFile" :label="$t('global.upload_external_file_input')" prepend-icon=""
        prepend-inner-icon="mdi-paperclip" outlined :show-size="1000" accept=".gpx,.kml,.geojson,.zip,.kmz,.dxf"
        :rules="fileRules" @click:clear="clearSelection" :error-messages="errorMessages">
        <template v-slot:selection="{ text }">
            <v-chip color="primary accent-4" dark label small>
                {{ text }}
            </v-chip>
        </template>
    </v-file-input>
</template>
<script>
export default {
    props: ["value"],
    data() {
        return {
            maxFileSize: 1024 * 1024 * 512, // 512 MB (adjust as needed)
            errorMessages: [],
            fileRules: [
                (v) => !!v || this.$t('global.upload_external_file_required'),
                (v) => !v || this.isFileSizeValid(v) || this.$t('global.upload_external_file_too_large'),
            ],
        }
    },
    computed: {
        selectedFile: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
    methods: {
        isFileSizeValid(file) {
            return file.size <= this.maxFileSize;
        },
        clearSelection() {
            this.errorMessages = [];
        }
    }
}
</script>