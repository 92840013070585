<template>
    <v-autocomplete v-model="selectedCrs" :items="options" cache-items flat hide-no-data hide-details outlined
        :label="$t('global.crs_selection_input')" item-text="displayText" item-value="code"
        prepend-inner-icon="mdi-map" :disabled="disabled"></v-autocomplete>
</template>
<script>
export default {
    props: ["value", "disabled"],
    data() {
        return {
            options: [
                {
                    code: "EPSG:4326",
                    displayText: "EPSG:4326 (WGS 84)",
                },
            ],
        }
    },
    computed: {
        selectedCrs: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
};
</script>