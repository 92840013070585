<template>
  <div>
    <v-card class="pa-0 ma-0" flat>
      <v-card-text class="pb-4">
        <div class="mb-1" v-for="(prop, index) in clusterProperties" :key="index">
          <p class="text-subtitle-2 text-left pb-0 mb-0">{{ prop }}</p>
          <p class="text-justify pb-0 mb-0" style="font-size:0.75rem" v-html="item[prop]"></p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["item"],
  computed: {
    clusterProperties() {
      return this.item ? Object.keys(this.item).filter((k) => {
        return !["id", "code", "name", "label", "description", "observations", "layer_id", "_svgImage", "_isPointCluster"].includes(k) && !k.endsWith('_img_unhovered') && this.item[k] && this.item[k].trim() !== '';
      }) : [];
    }
  },
};
</script>

<style></style>
