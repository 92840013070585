<template>
  <v-dialog v-model="isOpen" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title><v-icon left>mdi-information-outline</v-icon>{{ $t("global.help") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="!!currentPackage" class="py-5 px-2 fill-width overflow-y-auto"
        style="height: calc(100vh - 48px)">

        <p class="text-h6">{{ currentPackage.name }}</p>
        <p class="text-justify">{{ currentPackage.description }}</p>

        <p class="text-h6">FAQs</p>
        <v-expansion-panels accordion flat tile>
          <v-expansion-panel v-for="(video, index) in videos" :key="index">
            <v-expansion-panel-header>
              {{ video.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-center">
              <Video :url="video.url"></Video>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Video from "@/components/Help/Video";
export default {
  components: { Video },
  computed: {
    isOpen: {
      get() {
        return this.$store.state.ui.helpDialogOpen;
      },
      set(val) {
        this.$store.dispatch("ui/SET_HELP_DIALOG_OPEN", val);
      },
    },
    currentPackage() {
      return this.$store.state.packages.currentPackage;
    }
  },
  data(vm) {
    return {
      videos: [
        { title: vm.$t('global.faq_1_q'), url: '/help/videos/01.mp4' },
        { title: vm.$t('global.faq_2_q'), url: '/help/videos/02.mp4' },
        { title: vm.$t('global.faq_3_q'), url: '/help/videos/03_ide_pesquisar_elementos.mp4' },
        { title: vm.$t('global.faq_4_q'), url: '/help/videos/04_ide_medicoes.mp4' },
        { title: vm.$t('global.faq_5_q'), url: '/help/videos/05_ide_impressao_mapa.mp4' },
        { title: vm.$t('global.faq_6_q'), url: '/help/videos/06_carregar_temas_vet_externos.mp4' },
      ],
    };
  },
};
</script>
