<template>
  <div :style="'width:' + fieldWidth">
    <v-text-field v-model="term" tabindex="1" single-line hide-details outlined dense solo flat
      :label="$t('global.search')" :width="fieldWidth" @keydown.enter="search">
      <template v-slot:append>
        <v-btn small text fab color="primary" @click.stop="search">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  props: ["mapRef"],
  data() {
    return {
      term: "",
    };
  },
  computed: {
    activeLayers() {
      return this.$store.state.layers.activeLayers
        ? this.$store.state.layers.activeLayers
        : [];
    },
    package_id() {
      return this.$route.params.id;
    },
    fieldWidth() {
      return this.$vuetify.breakpoint.width <= 800 ? "100%" : "450px";
    },
    resultsOpen() {
      return this.$store.state.ui.searchOpen;
    },
    searchMode() {
      return this.$store.state.ui.searchMode;
    }
  },
  watch: {
    resultsOpen() {
      if (!this.resultsOpen) {
        this.term = this.$store.state.ui.searchTerm;
      }
    },
  },
  methods: {
    search() {
      let bbox = null;
      if (this.searchMode == 'visible-area' && this.mapRef) {
        bbox = this.mapRef.getBounds();
      }
      return this.$store.dispatch("ui/SET_SEARCH_TERM", {
        package_id: this.package_id,
        term: this.term,
        bbox: bbox,
        active_layers: this.activeLayers,
      });
    },
  },
};
</script>
