<template>
    <v-form ref="form">
        <v-alert dense text color="grey" class="text-caption my-2"
            v-html="$t('global.go_to_address_explanation')"></v-alert>
        <v-text-field class="mt-4" v-model="address" dense :label="$t('global.address')" outlined
            :rules="addressRules"></v-text-field>
        <div class="d-flex flex-row justify-space-between align-center">
            <v-btn depressed color="primary" @click.stop="goToAddress" :disabled="loading" :loading="loading">
                <v-icon left>mdi-magnify</v-icon>
                {{ $t('global.search_by_address') }}
            </v-btn>
            <v-btn depressed @click.stop="clear" :disabled="loading || results.length == 0">
                <v-icon left>mdi-trash-can-outline</v-icon>
                {{ $t('global.clear') }}
            </v-btn>
        </div>
        <div v-if="results && results.length > 0" class="mt-4">
            <v-divider inset class="my-2"></v-divider>
            <v-card flat tile>
                <v-card-subtitle class="px-1 py-1 font-weight-bold">{{ $t('global._x_results_found', {
                    count: results.length
                })
                }}</v-card-subtitle>
                <v-card-text class="px-1 overflow-y-auto" style="max-height:30vh;">
                    <div v-for="(item, index) in results" :key="index">
                        <div class="d-flex flex-row justify-space-between align-center text-caption">
                            <div>{{ item.properties.display_name }}</div>
                            <div class="ml-2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" icon @click.stop="zoomInOnElement(item)">
                                            <v-icon>mdi-magnify-plus-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('global.zoom_in_on_element') }}</span>
                                </v-tooltip>
                            </div>
                        </div>
                        <v-divider class="my-1"></v-divider>
                    </div>
                </v-card-text>
            </v-card>
        </div>
    </v-form>
</template>
<script>
import { EventBus } from "@/helpers/event-bus";

export default {
    data() {
        return {
            address: "",
            addressRules: [
                (v) => !!v || this.$t('global.mandatory_field'),
            ],
            results: [],
        };
    },
    computed: {
        loading() {
            return this.$store.state.search.loadingByAddress;
        }
    },
    methods: {
        async goToAddress() {
            this.results = [];
            if (this.$refs.form.validate()) {
                try {
                    let result = await this.$store.dispatch(
                        "search/GET_BY_ADDRESS",
                        this.address
                    );
                    this.results = result?.features;
                    EventBus.$emit("focusAddressResults", result);
                } catch (e) {
                    console.warn(e);
                    this.results = [];
                }
            }
        },
        clear() {
            this.results = [];
            this.address = "";
            EventBus.$emit("focusAddressResults", null);
        },

        zoomInOnElement(feature) {
            EventBus.$emit("zoomInOnFeature", feature);
        }
    }
};
</script>