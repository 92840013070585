<template>
  <v-card height="100%" @click="changeToNextLayer" tile>
    <v-img
      width="100"
      height="100"
      class="white--text align-end"
      :src="getNextBaseLayerImagePath()"
    >
      <v-badge
        v-if="selected == currentLayer"
        bordered
        color="primary"
        icon="mdi-check"
        overlap
        offset-x="-70"
        offset-y="-70"
      >
      </v-badge>
    </v-img>
    <v-card-actions class="pa-0">
      <p
        class="pa-0 ma-0 text-center"
        style="
          width: 100%;
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
        "
      >
        {{ layerName }}
      </p>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: ["value", "option", "selected", "layerName"],
  data: function () {
    return {
      currentLayer: this.option,
    };
  },
  methods: {
    changeToNextLayer() {
      this.$emit("optionSelected", this.currentLayer);
    },
    getNextBaseLayer() {
      return this.currentLayer;
    },
    getNextBaseLayerImagePath() {
      return require("@/assets/basemaps/" + this.getNextBaseLayer() + ".png");
    },
    getNextBaseLayerName() {
      return this.currentLayer;
    },
  },
};
</script>
