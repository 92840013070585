<template>
  <div class="fill-height fill-width" style="position: relative;">
    <div class="map" ref="map"></div>
    <div ref="popup" class="info-popup">
      <FeaturePopup v-model="openFeaturePopup" :items="foundFeaturesOnClick"></FeaturePopup>
    </div>
    <BaseLayersSwitcher v-model="selectedBaseLayer" />
    <PrintMapDialog v-model="mapPrintDialogOpen" :image="mapPrintedImage" :metadata="mapMetadata"></PrintMapDialog>
  </div>
</template>

<script>

// Base map imports
import {
  Map as Maplibre,
  GeolocateControl,
  ScaleControl,
  NavigationControl,
  Popup,
} from "maplibre-gl";

// Adicional components
import BaseLayersSwitcher from "@/components/Map/BaseLayersSwitcher";
import FeaturePopup from "@/components/Map/FeaturePopup";
import PrintMapDialog from "@/components/Map/PrintMapDialog";


// Map plugins
import NumericScaleControl from "@/plugins/map/NumericScaleControl";
import MouseCoordinatesControl from "@/plugins/map/MouseCoordinatesControl";
import FitZoomToActiveLayersControl from "@/plugins/map/FitZoomToActiveLayersControl";
import MeasuresControl from "maplibre-gl-measures";
import UploadExternalControl from "@/plugins/map/UploadExternalControl";
import PrintMapControl from "@/plugins/map/PrintMapControl";

// Shared event bus for inter component event handling
import { EventBus } from "@/helpers/event-bus";

// Dependencies
import * as turf from "@turf/turf";

// Constants and map helpers
import { AVAILABLE_BASE_LAYERS } from '@/helpers/Map/availablebaselayers';
import { AVAILABLE_FIXED_SCALES } from '@/helpers/Map/availablefixedscales';
import * as builder from '@/helpers/Map/layerbuilder';
import CustomMapMarkerSVG from "@/helpers/CustomMapMarkers/CustomMapMarkerSVG";
import { svgUtils } from "@/helpers/svg-utils";

// Default padding to fit layers/features
const FIT_BOUNDS_PADDING = 40;

// Import this base maplibre css
import "maplibre-gl/dist/maplibre-gl.css";

// Import aditional plugin css files
import "@/plugins/map/NumericScaleControl.css";
import "@/plugins/map/MouseCoordinatesControl.css";

export default {
  props: ["mapRef"],
  components: { BaseLayersSwitcher, FeaturePopup, PrintMapDialog },
  computed: {
    currentPackage() {
      return this.$store.state.packages.currentPackage;
    },
    allLayers() {
      return this?.currentPackage?.layers.map((l) => l.children).flat() ?? [];
    },
    selectedLayers: {
      get() {
        return this.$store.state.layers.activeLayers;
      },
      set(val) {
        this.$store.dispatch("layers/SET_ACTIVE_LAYERS", val);
      }
    },
    externalLayers() {
      return this.$store.state.external_layers.layers;
    },
    selectedFeatureDetails() {
      return this.$store.state.features.selectedFeatureDetails;
    },
    package_id() {
      return this.$route.params.id;
    },
    packageDefaultBaseLayer() {
      let settings = this.currentPackage.settings;
      if (settings.map_isdefault_osm === "true")
        return "osm";
      if (settings.map_isdefault_binghyb === "true")
        return "bing_hybrid";
      if (settings.map_isdefault_bingmap === "true")
        return "bing";
      if (settings.map_isdefault_googlehyb === "true")
        return "google_hybrid";
      if (settings.map_isdefault_googlemap === "true")
        return "google_road";
      if (settings.map_isdefault_whitepane === "true")
        return "whitepane";
      return "osm";
    }
  },
  data() {
    return {
      map: null,
      selectedBaseLayer: null,
      layers: [],
      drawnExternalLayers: [],
      hoveredFeatures: [],
      selectedFeatures: [], // features that belong to styled server layers 
      foundFeaturesOnClick: [], // all features eventually clicked on the map 
      openFeaturePopup: false,
      actualDevicePixelRatio: null,
      numericScaleControl: null, // instance for the numeric scale to obtain its value

      // Relative to the print functionality
      mapPrintDialogOpen: false,
      mapPrintedImage: null,
      mapMetadata: null,
    }
  },
  watch: {
    selectedBaseLayer(_, oldVal) {
      if (oldVal == null)
        return;

      if (this.selectedBaseLayer && this.map) {
        this.map.setStyle(AVAILABLE_BASE_LAYERS[this.selectedBaseLayer]);

        if (
          this.selectedBaseLayer == "google_hybrid" ||
          this.selectedBaseLayer == "google_road"
        ) {
          this.map.setMaxZoom(19);
        } else {
          this.map.setMaxZoom(22);
        }
        this.layers = [];
        this.showOrHideLayers();
        this.showOrHideExternalLayers();
      }
    },
    openFeaturePopup(val) {
      if (!val && this.popup) {
        this.popup.remove();
      }
    },

    selectedFeatures(newSelectedFeatures, oldSelectedFeatures) {
      oldSelectedFeatures.forEach((f) => {
        if (!f.id || !this.map.getSource(f.source))
          return;
        this.map.setFeatureState(
          { source: f.source, id: f.id },
          { selected: false }
        );
        if (f.layer && f.layer.type == 'symbol') {
          let styleMapSuffix = f.properties.style_class_id
            ? `_${f.properties.style_class_id}`
            : "";
          this.map.setLayoutProperty(f.layer.id, "icon-image", [
            "match",
            ["get", "id"],
            f.id,
            `${f.properties.layer_id}_img_unhovered` + styleMapSuffix,
            ["get", "_svgImage"],
          ]);
        }

      });

      newSelectedFeatures.forEach((f) => {
        if (!f.id || !this.map.getSource(f.source))
          return;
        this.map.setFeatureState(
          { source: f.source, id: f.id },
          { selected: true }
        );
        if (f.layer && f.layer.type == 'symbol') {
          let styleMapSuffix = f.properties.style_class_id
            ? `_${f.properties.style_class_id}`
            : "";
          this.map.setLayoutProperty(f.layer.id, "icon-image", [
            "match",
            ["get", "id"],
            f.id,
            `${f.properties.layer_id}_img_selected` + styleMapSuffix,
            ["get", "_svgImage"],
          ]);
        }
      });
    },

    // Running a watcher for selectedLayers length so that any change to this array triggers this
    'selectedLayers.length'() {
      this.showOrHideLayers();
    },

    // Running a watcher so that any change to this array triggers this
    externalLayers: {
      handler() {
        this.showOrHideExternalLayers();
      }, deep: true,
    },
  },

  mounted() {
    this.initMap();

    //Register them here
    EventBus.$on("focusFeature", this.handleFocusOnFeature);
    EventBus.$on("fitToBounds", this.handleFitToBounds);
    EventBus.$on("refreshLayer", this.handleLayerRefresh);
    EventBus.$on("goToCoords", this.handleGoToCoords);
    EventBus.$on("focusPostalCodeResults", this.handlePostalCodeResults);
    EventBus.$on("focusAddressResults", this.handleAddressResults);
    EventBus.$on("zoomInOnFeature", this.handleZoomInOnFeature);
    EventBus.$on("focusOnUploadedLayer", this.handleFocusOnUploadedLayer);
    EventBus.$on("setWmsOpacity", this.handleSetWmsOpacity);
  },

  beforeDestroy() {
    // Destroy them here
    EventBus.$off("focusFeature");
    EventBus.$off("fitToBounds");
    EventBus.$off("refreshLayer");
    EventBus.$off("goToCoords");
    EventBus.$off("focusPostalCodeResults");
    EventBus.$off("focusAddressResults");
    EventBus.$off("zoomInOnFeature");
    EventBus.$off("focusOnUploadedLayer");
    EventBus.$off("setWmsOpacity");
  },

  methods: {
    initMap() {
      let zoom = this?.currentPackage?.settings?.map_zoom ?? 13;
      let center = this?.currentPackage?.settings ? [
        this.currentPackage.settings.map_longitude,
        this.currentPackage.settings.map_latitude,
      ] : [-8.701334175928991, 41.1839965046249];
      this.map = new Maplibre({
        container: this.$refs.map,
        style: AVAILABLE_BASE_LAYERS[this.packageDefaultBaseLayer],
        center: center,
        zoom: zoom,
        antialias: true,
        maxZoom: 22,
        locale: {
          "GeolocateControl.FindMyLocation": this.$t('global.go_to_my_current_location'),
          "NavigationControl.ResetBearing": this.$t('global.reset_north_position'),
          "NavigationControl.ZoomIn": this.$t('global.zoom_in'),
          "NavigationControl.ZoomOut": this.$t('global.zoom_out'),
          "FitZoom.Title": this.$t('global.zoom_to_active_layers'),
          "MouseCoordinates.Title": this.$t('global.copy_coordinates'),
          "PrintMap.Title": this.$t('global.print_map'),
          "UploadExternal.Title": this.$t('global.upload_external_title'),
        },
      });
      this.initMapPlugins();

      this.map.on('load', () => {
        this.showOrHideLayers();
      });

      this.map.on('uploadexternal.open', () => {
        this.$store.dispatch("ui/OPEN_UPLOAD_EXTERNAL_LAYER");
      });

      this.map.on('print_control.click', () => {
        this.mapPrintDialogOpen = true;
        this.mapPrintedImage = null;
        this.mapMetadata = null;
        this.actualDevicePixelRatio = window.devicePixelRatio;
        let dpi = 300;
        this.setWindowDPI(dpi / 96); // 96 is the default dpi for aspect ratio = 1
        setTimeout(() => {
          this.map.once('idle', () => {
            this.mapPrintedImage = this.map.getCanvas().toDataURL("image/png");

            // this should contain the current map info: scale, orientation, tilt, etc.
            this.mapMetadata = {
              bearing: this.map.getBearing(),
              bounds: this.map.getBounds(),
              scale: this.numericScaleControl.getScaleValue(),
            };

            this.setWindowDPI(this.actualDevicePixelRatio);
            this.map.resize();
          });
          this.map.resize();
        }, 1000);
      });
      this.$emit('update:mapRef', this.map);
    },

    setWindowDPI(dpi) {
      Object.defineProperty(window, "devicePixelRatio", {
        get: function () {
          return dpi;
        }
      });
    },

    initMapPlugins() {
      if (!this.map)
        return;

      this.map.addControl(new MouseCoordinatesControl({}), "bottom-right");
      this.numericScaleControl = new NumericScaleControl({
        scales: AVAILABLE_FIXED_SCALES,
      });
      this.map.addControl(
        this.numericScaleControl,
        "bottom-right"
      );
      this.map.addControl(
        new ScaleControl({
          unit: "metric",
        }),
        "bottom-right"
      );
      this.map.addControl(new NavigationControl(), "top-left");
      this.map.addControl(
        new GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
        }),
        "top-left"
      );
      this.map.addControl(new FitZoomToActiveLayersControl(), "top-left");

      this.map.addControl(
        new UploadExternalControl({}),
        "top-left"
      );

      if (!this.$vuetify.breakpoint.mobile) {

        this.map.addControl(
          new PrintMapControl({}),
          "top-left"
        );

        this.map.addControl(new MeasuresControl({
          lang: {
            areaMeasurementButtonTitle: this.$t('global.measure_area'),
            lengthMeasurementButtonTitle: this.$t('global.measure_length'),
            clearMeasurementsButtonTitle: this.$t('global.clear_measures'),
          },
        }), "top-left");
      }
    },

    registerMapEvents() {
      //to unregister an event from map the function must be sent
      if (!this.map)
        return;
      this.map.off("click", this.handleMouseClick);
      this.map.on("click", this.handleMouseClick);
    },

    handleFocusOnUploadedLayer(layer) {
      if (layer && layer.geojson) {
        try {
          let bbox = turf.bbox(layer.geojson);
          this.map.fitBounds(bbox, {
            padding: FIT_BOUNDS_PADDING,
            maxZoom: 18,
          });
        } catch (e) {
          console.warn("Cannot fit bounds to layer", e, layer);
        }

      }
    },

    handleSetWmsOpacity({ layer, opacity }) {
      if (layer?.layerdata?._addedLayerIds) {
        layer.layerdata._addedLayerIds.forEach((lid) => {
          if (this.map.getLayer(lid)) {
            this.map.setPaintProperty(
              lid,
              "raster-opacity",
              parseInt(opacity, 10) / 100
            );
          }
        });
      }
    },

    async showOrHideExternalLayers() {
      let layersToHide = this.drawnExternalLayers.filter(
        (x) => !this.externalLayers.some((y) => y.id == x.id && y._isActive === true)
      );
      layersToHide.forEach((l) => {
        this.hideExternalLayer(l);
      });
      for (const l of this.externalLayers.filter((x) => x._isActive)) {
        await this.showExternalLayer(l);
      }
    },

    hideExternalLayer(layer) {
      let foundIdx = this.drawnExternalLayers.findIndex((el) => el.id == layer.id);
      if (foundIdx !== -1) {
        let removedLayer = this.drawnExternalLayers.splice(foundIdx, 1).shift();
        if (removedLayer._addedLayerIds) {
          removedLayer._addedLayerIds.forEach((lid) => {
            if (this.map.getLayer(lid)) {
              this.map.removeLayer(lid);
            }
          });
        }
        if (removedLayer._sourceId && this.map.getSource(removedLayer._sourceId)) {
          this.map.removeSource(removedLayer._sourceId);
        }
      }
    },

    showExternalLayer(layer) {
      let sourceId = `s-ex-${layer.id}_uploaded`;
      let circleLayerId = `l-ex-${layer.id}-_crl`;
      let fillLayerId = `l-ex-${layer.id}-_f`;
      let outlineLayerId = `l-ex-${layer.id}-_ol`;
      let lineLayerId = `l-ex-${layer.id}-_l`;

      if (this.map.getLayer(circleLayerId)) {
        this.map.removeLayer(circleLayerId);
      }

      if (this.map.getLayer(fillLayerId)) {
        this.map.removeLayer(fillLayerId);
      }

      if (this.map.getLayer(outlineLayerId)) {
        this.map.removeLayer(outlineLayerId);
      }

      if (this.map.getLayer(lineLayerId)) {
        this.map.removeLayer(lineLayerId);
      }

      if (this.map.getSource(sourceId)) {
        this.map.removeSource(sourceId);
      }

      this.map.addSource(sourceId, {
        'type': 'geojson',
        'data': layer.geojson
      });

      // For points
      this.map.addLayer({
        id: circleLayerId,
        type: "circle",
        source: sourceId,
        metadata: {
          "source:type": "external_layer"
        },
        paint: {
          "circle-radius": parseInt(layer.style.pointRadius),
          "circle-stroke-width": parseInt(layer.style.lineWidth),
          "circle-stroke-color": layer.style.lineColor,
          "circle-color": layer.style.fillColor,
          "circle-opacity": layer.style.fillOpacity * 0.01,
          "circle-stroke-opacity": layer.style.lineOpacity * 0.01,
        },
        filter: ["==", "$type", "Point"],
      });

      // For polygons
      this.map.addLayer({
        id: fillLayerId,
        type: "fill",
        source: sourceId,
        metadata: {
          "source:type": "external_layer"
        },
        paint: {
          "fill-color": layer.style.fillColor,
          "fill-opacity": layer.style.fillOpacity * 0.01,
        },
        filter: ["==", "$type", "Polygon"],
      });
      this.map.addLayer({
        id: outlineLayerId,
        type: "line",
        source: sourceId,
        metadata: {
          "source:type": "external_layer"
        },
        paint: {
          "line-width": parseInt(layer.style.lineWidth),
          "line-color": layer.style.lineColor,
          "line-opacity": layer.style.lineOpacity * 0.01,
        },
        filter: ["==", "$type", "Polygon"],
      });

      // For linestrings
      this.map.addLayer({
        id: lineLayerId,
        type: "line",
        source: sourceId,
        metadata: {
          "source:type": "external_layer"
        },
        paint: {
          "line-width": parseInt(layer.style.lineWidth),
          "line-color": layer.style.lineColor,
          "line-opacity": layer.style.lineOpacity * 0.01,
        },
        filter: ["==", "$type", "LineString"],
      });
      this.drawnExternalLayers.push({
        id: layer.id,
        _sourceId: sourceId,
        _addedLayerIds: [
          circleLayerId,
          fillLayerId,
          outlineLayerId,
          lineLayerId,
        ],
      });
    },

    async showOrHideLayers() {
      let layersToHide = this.layers.filter(
        (x) => !this.selectedLayers.some((y) => y.id == x.id)
      );
      layersToHide.forEach((l) => {
        this.hideLayer(l);
      });

      for (const l of this.selectedLayers) {
        await this.showLayer(l);
      }
      this.registerMapEvents();
    },

    async showLayer(layer) {
      if (!layer || layer._isLoading)
        return;
      let foundIdx = this.layers.findIndex((el) => el.id == layer.id);
      if (foundIdx == -1) {
        await this.loadLayer(layer);
      }
    },

    hideLayer(layer) {
      let foundIdx = this.layers.findIndex((el) => el.id == layer.id);
      if (foundIdx !== -1) {
        let removedLayer = this.layers.splice(foundIdx, 1).shift();

        //Close existing opened popups
        this.openFeaturePopup = false;

        // Before removing any layers, we must deselect all existing selections
        if (removedLayer._sourceId)
          this.selectedFeatures = this.selectedFeatures.filter((f) => f.source !== removedLayer._sourceId);

        if (removedLayer._addedLayerIds) {
          removedLayer._addedLayerIds.forEach((lid) => {
            if (this.map.getLayer(lid)) {
              this.map.off("mousemove", lid, this.handleMouseMove);
              this.map.off("mouseleave", lid, this.handleMouseLeave);
              this.map.removeLayer(lid);
            }
          });
        }
        if (removedLayer._sourceId && this.map.getSource(removedLayer._sourceId)) {
          this.map.removeSource(removedLayer._sourceId);
        }
      }
    },

    async loadLayer(layerObj) {
      if (layerObj && layerObj.layer) {
        let geojson = await this.$store
          .dispatch("layers/GET_LAYER", {
            package_id: this.package_id,
            layer_id: layerObj.id,
            style_map_id: layerObj.layer.default_style_map_id,
            layer: layerObj,
          });
        await this.createMapLayerAndSource(layerObj.id, geojson);
      }
    },

    buildLayerSource(id, spec) {
      let sourceData = null;
      if (spec.layertype === 'WMS') {
        let baseUrl = spec.wmslayers[0].url;
        let crs = spec.wmslayers[0].crs;
        let crsCode = crs.split(":");
        let imageFormat = spec.wmslayers[0].format;
        let layerName = spec.wmslayers[0].layername;
        const TILE_SIZE = 256;
        let url = new URL(baseUrl);
        let params = url.searchParams;
        params.set("format", imageFormat);
        params.set("service", `WMS`);
        params.set("request", `GetMap`);
        params.set("srs", crs);
        params.set("width", TILE_SIZE);
        params.set("height", TILE_SIZE);
        params.set("layers", layerName);
        params.set("transparent", 'true');
        if (!params.has('version')) {
          params.set("version", '1.1.1');
        }
        if (!params.has('styles')) {
          params.set("styles", '');
        }
        sourceData = {
          type: "raster",
          tiles: [
            `${url.origin}${url.pathname}?bbox={bbox-epsg-${crsCode[1]}}&${params.toString()}`,
          ],
          tileSize: TILE_SIZE,
        }
      } else {
        // TODO - this should come already parsed from the server 
        if (spec.layertype !== "PointCluster") {
          spec.features = spec.features.map((f) => {
            let styleMapSuffix = f.properties.style_class_id
              ? `_${f.properties.style_class_id}`
              : "";
            f.geometry = JSON.parse(f.geometry);
            f.properties._svgImage = `${id}_img_unhovered` + styleMapSuffix;
            f.properties = this.updateFeatureProperties(spec, f.properties);
            return f;
          });
        } else {
          let features = spec.features.map((f) => {
            return f.geometries.map((g) => {
              let styleMapSuffix = f.properties.style_class_id
                ? `_${f.properties.style_class_id}`
                : "";
              Object.assign(g.properties, f.properties);
              g.properties._svgImage = `${id}_img_unhovered` + styleMapSuffix;
              g.properties._isPointCluster = true;
              return {
                "type": "Feature",
                "geometry": {
                  "coordinates": g.coordinates,
                  "type": g.type,
                },
                "properties": g.properties
              }
            });
          }).flat(1);
          spec.features = features;
        }
        sourceData = {
          type: "geojson",
          data: spec,
          promoteId: "id",
        };
        if (spec.layertype === "Point" || spec.layertype === "PointCluster") {
          Object.assign(sourceData, {
            cluster: true,
            clusterRadius: 20,
          });
        }
      }
      return {
        sourceId: `s_${id}`,
        sourceData: sourceData,
      };
    },

    /**
     * MOUSE EVENTS
     */
    handleMouseClick(e) {
      let matchedFeatures = this.map.queryRenderedFeatures(e.point);
      this.foundFeaturesOnClick = matchedFeatures.filter((f) => {
        return f &&
          (
            !f?.properties?.cluster && // ignore clusters
            !f?.properties?.measurement && // ignore measurement labels
            !f?.properties?.meta // ignore mapbox draw
          );
      });
      this.selectedFeatures = this.foundFeaturesOnClick.filter((f) => {
        return f.id;
      });
      if (this.foundFeaturesOnClick.length > 0) {
        this.popup = new Popup({})
          .setLngLat(e.lngLat)
          .setDOMContent(this.$refs.popup)
          .setMaxWidth("350px")
          .addTo(this.map);
        this.openFeaturePopup = true;
      }
    },

    handleMouseLeave() {
      this.hoveredFeatures.forEach((f) => {
        if (!f.id)
          return;
        this.map.setFeatureState(
          { source: f.source, id: f.id },
          { hover: false }
        );

        if (f.layer.type == 'symbol') {
          let currentState = this.map.getFeatureState({ source: f.source, id: f.id });
          let styleMapSuffix = f.properties.style_class_id
            ? `_${f.properties.style_class_id}`
            : "";
          this.map.setLayoutProperty(f.layer.id, "icon-image", [
            "match",
            ["get", "id"],
            f.id,
            currentState?.selected ? `${f.properties.layer_id}_img_selected` + styleMapSuffix : `${f.properties.layer_id}_img_unhovered` + styleMapSuffix,
            ["get", "_svgImage"],
          ]);
        }
      });
      this.hoveredFeatures = [];
      this.map.getCanvas().style.cursor = "grab";
    },

    handleMouseMove(e) {
      if (e.features.length > 0) {
        this.map.getCanvas().style.cursor = "pointer";
        e.features.forEach((f) => {
          if (!f.id)
            return;
          this.map.setFeatureState(
            { source: f.source, id: f.id },
            { hover: true }
          );
          if (f.layer.type == 'symbol') {
            let styleMapSuffix = f.properties.style_class_id
              ? `_${f.properties.style_class_id}`
              : "";
            this.map.setLayoutProperty(f.layer.id, "icon-image", [
              "match",
              ["get", "id"],
              f.id,
              `${f.properties.layer_id}_img_hovered` + styleMapSuffix,
              ["get", "_svgImage"],
            ]);
          }
          this.hoveredFeatures.push(f);
        });
      }
    },

    async buildAndAddLayers(id, spec, sourceId) {
      spec._addedLayerIds = [];
      var layers = [];
      switch (spec.layertype) {
        case "Point": {
          layers = await builder.buildPointLayers(id, spec, sourceId, this.map);
          break;
        }
        case "PointCluster": {
          layers = await builder.buildPointClusterLayers(id, spec, sourceId, this.map);
          break;
        }
        case "LineString": {
          layers = builder.buildLineStringLayers(id, spec, sourceId);
          break;
        }
        case "Polygon": {
          layers = builder.buildPolygonLayers(id, spec, sourceId);
          break;
        }
        case "GeometryCollection": {
          layers = await builder.buildGeometryCollectionLayers(id, spec, sourceId, this.map);
          break;
        }
        case "WMS": {
          layers = builder.buildWmsLayers(id, spec, sourceId);
          break;
        }
      }

      layers.forEach((l) => {
        if (!this.map.getLayer(l.id)) this.map.addLayer(l.layer);
        spec._addedLayerIds.push(l.id);
      });

      // WMS do not have hover events
      if (spec.layertype !== 'WMS') {
        spec._addedLayerIds.forEach((lid) => {
          this.map.on("mousemove", lid, this.handleMouseMove);
          this.map.on("mouseleave", lid, this.handleMouseLeave);
        })
      }
    },

    async createMapLayerAndSource(id, spec) {
      if (!this.map) {
        return;
      }
      // Add the source
      let { sourceId, sourceData } = this.buildLayerSource(id, spec);
      if (!this.map.getSource(sourceId))
        this.map.addSource(sourceId, sourceData);

      // Add the necessary layers
      await this.buildAndAddLayers(id, spec, sourceId);
      spec._sourceId = sourceId;
      this.layers.push(spec);
    },

    async handleFocusOnFeature(item) {
      if (!item || !item.feature || !this.selectedFeatureDetails)
        return;
      let bbox = turf.bbox({
        type: "GeometryCollection",
        geometries: [this.selectedFeatureDetails.geojson],
      });
      this.map.fitBounds(bbox, {
        padding: FIT_BOUNDS_PADDING,
        maxZoom: 18,
      });

      // Select the element that was focused
      let sourceId = `s_${item.feature.layer_id}`; // our convention for the id

      // Focus on the feature
      this.selectedFeatures = [{ id: item.feature.id, source: sourceId, properties: item.feature }];

      // close any opened popup
      this.openFeaturePopup = false;

      // Activate the layer if not active
      let layerIsSelected = this.selectedLayers.some((l) => l.id === item.feature.layer_id);
      if (!layerIsSelected) {
        let layerToActivate = this.allLayers.find((l) => l.id === item.feature.layer_id);
        if (layerToActivate) {
          this.selectedLayers.push(layerToActivate);
        }
      }

      // Close this because we're in mobile
      if (this.$vuetify.breakpoint.mobile) {
        this.$store.dispatch('ui/SET_FEATURE_DETAILS_OPEN', false);
      }
    },

    handleFitToBounds(layer) {
      if (layer && layer.layerdata) {
        if (layer.layerdata.type == 'WMS') {
          this.$store.dispatch("layers/GET_WMS_BBOX", {
            package_id: this.package_id,
            layer_id: layer.id,
            layer: layer,
          }).then((data) => {
            if (data && data.bbox) {
              let bbox = [data.bbox.minx, data.bbox.miny, data.bbox.maxx, data.bbox.maxy] // bbox extent in minX, minY, maxX, maxY order;
              this.map.fitBounds(bbox, { padding: FIT_BOUNDS_PADDING, maxZoom: 18 });
            }
          });
        } else if (layer.layerdata.features.length > 0) {
          let f = layer.layerdata.features;
          let bbox = turf.bbox({
            type: "FeatureCollection",
            features: f,
          });
          this.map.fitBounds(bbox, { padding: FIT_BOUNDS_PADDING, maxZoom: 18 });
        }
        if (this.$vuetify.breakpoint.mobile) {
          this.$store.dispatch('ui/SET_NAVBAR_OPEN', false);
        }
      }
    },

    async handleLayerRefresh(layer) {
      if (layer) {
        this.hideLayer(layer);
        await this.showLayer(layer);
      }
    },

    handleGoToCoords(coords) {
      if (coords && this.map) {
        this.map.flyTo({
          center: [
            coords.lng,
            coords.lat
          ],
        });
      }
    },

    async handlePostalCodeResults(result) {
      const SEARCH_BY_POSTAL_CODE_POLYGON_SOURCE = 's-polygon-search-by-postal-code';
      const SEARCH_BY_POSTAL_CODE_POLYGON_LAYER = 'l-polygon-search-by-postal-code';
      const SEARCH_BY_POSTAL_CODE_POINTS_SOURCE = 's-points-search-by-postal-code';
      const SEARCH_BY_POSTAL_CODE_POINTS_LAYER = 'l-points-search-by-postal-code';
      if (this.map) {
        if (this.map.getLayer(SEARCH_BY_POSTAL_CODE_POLYGON_LAYER)) {
          this.map.removeLayer(SEARCH_BY_POSTAL_CODE_POLYGON_LAYER);
        }
        if (this.map.getSource(SEARCH_BY_POSTAL_CODE_POLYGON_SOURCE)) {
          this.map.removeSource(SEARCH_BY_POSTAL_CODE_POLYGON_SOURCE);
        }
        if (this.map.getLayer(SEARCH_BY_POSTAL_CODE_POINTS_LAYER)) {
          this.map.removeLayer(SEARCH_BY_POSTAL_CODE_POINTS_LAYER);
        }
        if (this.map.getSource(SEARCH_BY_POSTAL_CODE_POINTS_SOURCE)) {
          this.map.removeSource(SEARCH_BY_POSTAL_CODE_POINTS_SOURCE);
        }
        let icon = await CustomMapMarkerSVG.fetchRawSvgFaIcon(
          `FontAwesome/map-marker.svg`
        );
        const pointsIconImageId = 'pointImg';
        let { unhoveredSVG } = CustomMapMarkerSVG.buildSvg({
          "color": "#ffffff",
          "weight": 2,
          "radius": 10,
          "opacity": 1,
          "fillColor": "#f4a261",
          "fillOpacity": 0.75,
          "border": "solid",
          "icon": "fas fa-map-marker-alt",
          "iconShape": "circle",
          "iconColor": "#ffffff",
        }, icon);

        svgUtils.svgToPng(unhoveredSVG, (imgData) => {
          this.map.loadImage(imgData, (_, image) => {
            this.map.hasImage(pointsIconImageId)
              ? this.map.updateImage(pointsIconImageId, image)
              : this.map.addImage(pointsIconImageId, image);
          });
        });

        if (result) {
          if (result.polygon) {
            this.map.addSource(SEARCH_BY_POSTAL_CODE_POLYGON_SOURCE, {
              'type': 'geojson',
              'data': result.polygon
            });
            this.map.addLayer({
              'id': SEARCH_BY_POSTAL_CODE_POLYGON_LAYER,
              'type': 'fill',
              'source': SEARCH_BY_POSTAL_CODE_POLYGON_SOURCE,
              'metadata': {
                "source:type": "postal_code"
              },
              'layout': {},
              'paint': {
                'fill-color': '#f4a261',
                'fill-opacity': 0.3
              }
            });
          }
          this.map.addSource(SEARCH_BY_POSTAL_CODE_POINTS_SOURCE, {
            'type': 'geojson',
            'data': result.points
          });
          this.map.addLayer({
            'id': SEARCH_BY_POSTAL_CODE_POINTS_LAYER,
            'type': 'symbol',
            'source': SEARCH_BY_POSTAL_CODE_POINTS_SOURCE,
            'metadata': {
              "source:type": "postal_code"
            },
            'layout': {
              'icon-image': pointsIconImageId,
              'text-field': ['get', 'description'],
              'text-font': [
                "Klokantech Noto Sans Bold"
              ],
              'text-offset': [0, 1.25],
              "text-size": 12,
              'text-anchor': 'top'
            },
            'paint': {
              "text-color": "#ffffff"
            }
          });
          if (result.points && result?.points?.features.length > 0) {
            let bbox = turf.bbox(result.points);
            this.map.fitBounds(bbox, {
              padding: FIT_BOUNDS_PADDING,
              maxZoom: 18,
            });
          }
        }
      }
    },

    async handleAddressResults(result) {
      const SEARCH_BY_ADDRESS_LINE_LAYER = 'l-line-search-by-address';
      const SEARCH_BY_ADDRESS_LINE_SYMBOL_LAYER = 'ls-line-search-by-address';
      const SEARCH_BY_ADDRESS_LINE_SOURCE = 's-line-search-by-address';
      if (this.map) {
        if (this.map.getLayer(SEARCH_BY_ADDRESS_LINE_LAYER)) {
          this.map.removeLayer(SEARCH_BY_ADDRESS_LINE_LAYER);
        }

        if (this.map.getLayer(SEARCH_BY_ADDRESS_LINE_SYMBOL_LAYER)) {
          this.map.removeLayer(SEARCH_BY_ADDRESS_LINE_SYMBOL_LAYER);
        }

        if (this.map.getSource(SEARCH_BY_ADDRESS_LINE_SOURCE)) {
          this.map.removeSource(SEARCH_BY_ADDRESS_LINE_SOURCE);
        }

        if (result) {
          this.map.addSource(SEARCH_BY_ADDRESS_LINE_SOURCE, {
            'type': 'geojson',
            'data': result
          });
          this.map.addLayer({
            'id': SEARCH_BY_ADDRESS_LINE_LAYER,
            'type': 'line',
            'source': SEARCH_BY_ADDRESS_LINE_SOURCE,
            'metadata': {
              "source:type": "address"
            },
            'layout': {
              'line-join': 'round',
              'line-cap': 'round'
            },
            'paint': {
              'line-color': '#f4a261',
              'line-width': 10,
              'line-opacity': 0.8,
            }
          });

          this.map.addLayer({
            "id": SEARCH_BY_ADDRESS_LINE_SYMBOL_LAYER,
            "type": "symbol",
            "source": SEARCH_BY_ADDRESS_LINE_SOURCE,
            'metadata': {
              "source:type": "address"
            },
            "layout": {
              "symbol-placement": "line-center",
              "text-font": [
                "Klokantech Noto Sans Bold"
              ],
              "text-field": '{display_name}', // part 2 of this is how to do it
              "text-size": 12,
            },
            "paint": {
              "text-color": "#ffffff"
            }
          });

          if (result && result?.features && result.features.length > 0) {
            let bbox = turf.bbox(result);
            this.map.fitBounds(bbox, {
              padding: FIT_BOUNDS_PADDING,
              maxZoom: 18,
            });
          }
        }
      }
    },

    handleZoomInOnFeature(feature) {
      if (this.map && feature) {
        let bbox = turf.bbox(feature);
        this.map.fitBounds(bbox, {
          padding: FIT_BOUNDS_PADDING,
          maxZoom: 18,
        });
      }
    },

    updateFeatureProperties(spec, properties) {
      let newProps = Object.assign({}, properties);
      let styleToApply = spec.style;
      if (properties.style_class_id && spec.style_classes) {
        let s = spec.style_classes.find((sc) => sc.id == properties.style_class_id);
        if (s) {
          styleToApply = s.style;
        }
      }
      switch (spec.layertype) {
        case "Point":
        case "PointCluster":
          properties._svgImage = `${spec.id}_img_unhovered` + properties.style_class_id ? `_${properties.style_class_id}` : "";
          break;
        case "LineString": {
          newProps.color = styleToApply.color;
          newProps.opacity = parseFloat(styleToApply.opacity);
          break;
        }
        case "Polygon": {
          newProps.fillColor = styleToApply.fillColor;
          newProps.fillOpacity = parseFloat(styleToApply.fillOpacity);
          newProps.weight = parseInt(styleToApply.weight);
          newProps.color = styleToApply.color;
          newProps.opacity = parseFloat(styleToApply.opacity);
          break;
        }
        case "GeometryCollection": {
          newProps.color = styleToApply.color_link;
          newProps.opacity = parseFloat(styleToApply.opacity_link);
          break;
        }
      }
      return newProps;
    }
  }
}
</script>
<style scoped>
.map {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
<style>
.maplibregl-control-container>div {
  z-index: 1;
}

@media only screen and (max-width: 800px) {

  .mapboxgl-ctrl-top-left,
  .mapboxgl-ctrl-top-right,
  .maplibregl-ctrl-top-left,
  .maplibregl-ctrl-top-right {
    top: 120px !important;
  }
}

.info-popup {
  position: relative;
}
</style>