<template>
    <v-form ref="form">
        <v-alert dense text color="grey" class="text-caption my-2"
            v-html="$t('global.go_to_coordinates_explanation')"></v-alert>
        <v-row class="mt-2">
            <v-col>
                <v-text-field v-model="lat" maxlength="25" dense :label="$t('global.latitude')" outlined
                    :rules="latRules"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field v-model="lng" maxlength="25" dense :label="$t('global.longitude')" outlined
                    :rules="lngRules"></v-text-field>
            </v-col>
        </v-row>
        <v-btn depressed block color="primary" @click.stop="goToLatLng">
            <v-icon left>mdi-map-marker-radius</v-icon>
            {{ $t('global.go_to_coords') }}
        </v-btn>
    </v-form>
</template>
<script>
import { EventBus } from "@/helpers/event-bus";
export default {
    data() {
        return {
            lat: null,
            lng: null,
            latRules: [
                (v) => !!v || this.$t('global.mandatory_field'),
                (v) => !isNaN(v) || this.$t('global.invalid_lat'),
                (v) => (v && this.isValidLatitude(v)) || this.$t('global.lat_must_be_under'),
            ],
            lngRules: [
                (v) => !!v || this.$t('global.mandatory_field'),
                (v) => !isNaN(v) || this.$t('global.invalid_lng'),
                (v) =>
                    (v && this.isValidLongitude(v)) || this.$t('global.lng_must_be_under'),
            ],
        };
    },
    methods: {
        isValidLatitude(lat) {
            return isFinite(lat) && Math.abs(lat) <= 90;
        },
        isValidLongitude(lng) {
            return isFinite(lng) && Math.abs(lng) <= 180;
        },
        goToLatLng() {
            if (this.$refs.form.validate()) {
                EventBus.$emit("goToCoords", { lat: this.lat, lng: this.lng });
            }
        }
    },
};
</script>