import * as shp from "shpjs";
import toGeoJSON from "@mapbox/togeojson";
import jszip from "jszip/dist/jszip";
import configs from "@/helpers/configs";

export default class FileLayerLoader {
  constructor() {
    //https://gist.github.com/Jivings/9ed53bf46186d7bf8da6
    this._events = {
      "*": [],
    };
  }

  async loadCadFile(file, options) {
    this.trigger("loading", true);
    const formData = new FormData();
    formData.append("upload", file);
    formData.append("sourceSrs", options.sourceSrs);
    formData.append("targetSrs", options.targetSrs);
    let url = `${configs.getOgrApiUrl()}/convert`;
    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        this.trigger("loading", false);
        return null;
      }
      const geojson = await response.json(); // Use response.json() to parse the GeoJSON response
      let result = {
        data: geojson,
        ext: "dxf",
        options: options,
      };
      this.trigger("loaded", result);
      return result;
    } catch (error) {
      this.trigger("loading", false);
      console.log(error);
    }
    return null;
  }

  loadFile(file, ext, options) {
    return new Promise((resolve, reject) => {
      this.trigger("loading", true);
      var reader = new FileReader();
      reader.onload = (theFile) => {
        if (ext == "zip") {
          this.convertToSHPByZip(theFile.target.result, ext).then((r) => {
            r.options = options;
            this.trigger("loaded", r);
            resolve(r);
          });
        } else if (ext == "geojson" || ext == "json") {
          var geoJSONcontent = JSON.parse(theFile.target.result);
          let result = {
            data: geoJSONcontent,
            ext: "geojson",
            options: options,
          };
          this.trigger("loaded", result);
          resolve(result);
        } else if (ext == "kml") {
          let res = this.convertToGeoJSON(theFile.target.result, "kml");
          res.options = options;
          this.trigger("loaded", res);
          resolve(res);
        } else if (ext == "gpx") {
          let res = this.convertToGeoJSON(theFile.target.result, "gpx");
          res.options = options;
          this.trigger("loaded", res);
          resolve(res);
        } else if (ext == "kmz") {
          jszip.loadAsync(file).then(
            (zip) => {
              zip.forEach((relativePath, zipEntry) => {
                let fileExt = zipEntry.name.split(".").pop();
                if (fileExt == "kml") {
                  zip
                    .file(zipEntry.name)
                    .async("string")
                    .then((content) => {
                      let res = this.convertToGeoJSON(content, "kml");
                      res.options = options;
                      this.trigger("loaded", res);
                      resolve(res);
                    });
                }
              });
            },
            function (e) {
              console.log("Error reading " + file.name + ": " + e.message);
              reject(e);
            }
          );
        }
      };
      // Read the GeoJSON as text
      if (ext == "zip") reader.readAsArrayBuffer(file);
      else reader.readAsText(file, "UTF-8");
    });
  }

  laodMultipleFiles(files, options) {
    this.trigger("loading", true);
    var readers = [];
    var ext_local;
    var fileSHP, fileDBF, filePRJ;

    if (!files[0]) return readers;

    files.forEach((file) => {
      // Get extension of the file
      ext_local = file.name.split(".").pop();

      if (ext_local == "shp" || ext_local == "dbf" || ext_local == "prj") {
        if (ext_local == "shp") fileSHP = file;
        else if (ext_local == "dbf") fileDBF = file;
        else if (ext_local == "prj") filePRJ = file;

        if (fileSHP != null && fileDBF != null && filePRJ != null) {
          var result = this.loadShapeFiles(fileSHP, fileDBF, filePRJ, options);
          if (typeof result === "string") {
            //info error
            return result;
          }

          fileSHP = null;
          fileDBF = null;
          filePRJ = null;
        } else {
          if (files.length == 0) {
            return;
          }
        }
      } else {
        /* var _result = this.loadFile(file, ext);
                if (typeof _result === "string") {
                    //info error
                    return _result;
                } else readers.push(_result); */
      }
    });

    // return first reader (or false if no file),
    // which is also used for subsequent loadings
    return readers;
  }

  loadShapeFiles(fileSHP, fileDBF, filePRJ, options) {
    var readerSHP;

    // Check file is defined
    /*  if (
             this._isParameterMissing(fileSHP, "file") ||
             this._isParameterMissing(fileDBF, "file")
         ) {
             return Lang.get("viewer.error_parameter_missing");
         }
 
         // Check file size
         if (
             !this._isFileSizeOk(fileSHP.size) ||
             !this._isFileSizeOk(fileDBF.size)
         ) {
             return Lang.get("viewer.error_file_size");
         }
        */
    // Get parser for this data type
    this.trigger("loading", true);

    var readFile = (file, ext) => {
      var promise = new Promise((resolve, reject) => {
        try {
          var reader = new FileReader();
          reader.onload = (theFile) => {
            resolve(theFile.target.result);
          };
          //IDK
          if (!file.testing) {
            if (ext == "prj") reader.readAsText(file);
            else reader.readAsArrayBuffer(file);
          }
        } catch (err) {
          reject(err);
        }
      });

      return promise;
    };

    var promiseSHP = readFile(fileSHP, "shp");
    var promiseDBF = readFile(fileDBF, "dbf");
    var promisePRJ = readFile(filePRJ, "prj");

    Promise.all([promiseSHP, promiseDBF, promisePRJ]).then((values) => {
      var contentSHP = values[0];
      var contentDBF = values[1];
      var contentPRJ = values[2];

      if (contentSHP != null && contentDBF && contentPRJ != null) {
        var layer = this.convertToSHP(
          fileSHP.name,
          contentSHP,
          contentDBF,
          contentPRJ
        );
        this.trigger("loaded", {
          data: layer,
          ext: "shp",
          prj: contentPRJ,
          options: options,
        });
      }
    });

    return readerSHP;
  }

  convertToGeoJSON(content, /*  filename, */ format) {
    var geojson;
    // Format is either 'gpx' or 'kml'
    if (typeof content === "string") {
      content = new window.DOMParser().parseFromString(content, "text/xml");
    }
    geojson = toGeoJSON[format](content);
    return {
      data: geojson,
      ext: format,
    };
    //return this._loadGeoJSON(geojson, filename, false);
  }

  convertToSHPByZip(content, ext) {
    var promise = new Promise((resolve, reject) => {
      try {
        shp(content)
          .then((geojson) => {
            resolve({
              data: geojson,
              ext: ext,
            });
            //resolve(this._loadGeoJSON(geojson, filename, true));
          })
          .catch(() => {
            console.log("Shape from ZIP failed");
            reject(new Error("Shape from ZIP failed"));
          });
      } catch (err) {
        console.log("Shape from ZIP failed");
        reject(new Error("Shape from ZIP failed"));
      }
    });
    return promise;
  }

  convertToSHP(
    filename,
    contentSHP,
    contentDBF
    /*  contentPRJ */
  ) {
    var geoJSON = shp.combine([
      shp.parseShp(contentSHP /* contentPRJ */),
      shp.parseDbf(contentDBF),
    ]);
    return geoJSON;
  }

  validateFileForLoad(files) {
    if (files[0].name.split(".").pop() == "geojson") return true;
    else if (files[0].name.split(".").pop() == "zip") return true;
    else if (files[0].name.split(".").pop() == "kml") return true;
    else if (files[0].name.split(".").pop() == "gpx") return true;
    else return false;
  }

  validateShapeFileLoad(files) {
    let ext = [];
    files.forEach((file) => {
      ext.push(file.name.split(".").pop());
    });

    const match = ext.find((element) => {
      if (
        element.toLowerCase().includes("shp".toLowerCase()) ||
        element.toLowerCase().includes("prj".toLowerCase()) ||
        element.toLowerCase().includes("dbf".toLowerCase())
      ) {
        return true;
      }
    });
    if (match !== undefined) {
      return "IS_VALID_SHAPEFILE";
    } else {
      return "IS_INVALID_SHAPEFILE";
    }
  }

  /*  checkUnsupportedFileExt(files){
 
     } */

  /*  validateMultipleFiles(files) {
         let tooLarge = false;
         let ext = [];
         files.forEach(file => {
             ext.push(file.name.split(".").pop())
             if (file.size > 536870912) {
                 tooLarge = true;
                 return
             }
             else tooLarge = false;
         });
 
         if (tooLarge) return {
             message: "File too large",
             err: true,
         }
 
         const match = ext.find(element => {
             if (element.toLowerCase().includes("shp".toLowerCase())
                 || element.toLowerCase().includes("prj".toLowerCase())
                 || element.toLowerCase().includes("dbf".toLowerCase())) {
                 return true;
             }
         });
         return {
             message: "All good",
             err: false,
         }
     } */

  validateSingleFile(file) {
    let isForShapeFile = false;
    if (
      file.name.toLowerCase().includes("shp".toLowerCase()) ||
      file.name.toLowerCase().includes("prj".toLowerCase()) ||
      file.name.toLowerCase().includes("dbf".toLowerCase())
    ) {
      isForShapeFile = true;
    } else {
      isForShapeFile = false;
    }

    if (isForShapeFile) {
      return {
        message:
          "Ficheiros em falta, selecione apenas .shp .prj e .dbf para carregar uma shapefile ou um .zip da shapefile",
        err: true,
      };
    } else {
      if (
        file.name.toLowerCase().includes(".geojson".toLowerCase()) ||
        file.name.toLowerCase().includes(".gpx".toLowerCase()) ||
        file.name.toLowerCase().includes(".kml".toLowerCase()) ||
        file.name.toLowerCase().includes(".zip".toLowerCase()) ||
        file.name.toLowerCase().includes(".kmz".toLowerCase()) ||
        file.name.toLowerCase().includes(".dxf".toLowerCase())
      ) {
        return {
          message: "",
          err: false,
        };
      } else {
        return {
          message: "Ficheiro não suportado",
          err: true,
        };
      }
    }
  }

  trigger(eventName, evtData) {
    eventName.split(" ").forEach((evtName) => {
      // trigger a global event event
      this._events["*"].forEach((evt) =>
        evt.fn.call(evt.scope, evtName, evtData)
      );
      // if there are any listeners to this event
      // then fire their handlers
      if (this._events[evtName]) {
        this._events[evtName].forEach((evt) => {
          evt.fn.call(evt.scope, evtData);
        });
      }
    });
    return this;
  }

  on(eventName, fn, scope) {
    if (!this._events[eventName]) this._events[eventName] = [];
    this._events[eventName].push({
      eventName,
      fn,
      scope: scope || this,
    });
    return this;
  }

  off(eventName, fn) {
    if (!this._events[eventName]) return this;
    if (!fn) {
      this._events[eventName] = [];
    }
    this._events[eventName] = this._events[eventName].filter((evt) => {
      return evt.fn !== fn;
    });
    return this;
  }

  once(eventName, fn, scope) {
    const func = () => {
      fn.call(scope, ...arguments);
      this.off(eventName, func);
    };
    return this.on(eventName, func, scope);
  }
}
