<template>
  <v-dialog v-model="isOpen" :width="$vuetify.breakpoint.mobile ? '80%' : '60%'" max-width="800" persistent>
    <v-card tile>
      <v-toolbar color="primary" flat dark>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-upload</v-icon>{{ $t("global.upload_external") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="max-height: 50vh; overflow-y: auto">
        <v-alert outlined dense text color="blue" class="my-2">
          <ul class="text-caption black--text">
            <li v-html="$t('global.upload_external_shapefile_explanation')"></li>
            <li v-html="$t('global.upload_external_other_formats_explanation')"></li>
          </ul>
        </v-alert>
        <v-form ref="form" class="mt-4">
          <UploadFilesInput v-model="selectedFile"></UploadFilesInput>
          <CrsSelectionInput v-model="selectedCrs" :disabled="!selectedFile"></CrsSelectionInput>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" depressed :disabled="loading" @click.stop="upload">
          <v-icon left>mdi-upload</v-icon>{{ $t("global.upload") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UploadFilesInput from '@/components/ExternalLayers/UploadFilesInput';
import CrsSelectionInput from '@/components/ExternalLayers/CrsSelectionInput';
import FileLayerLoader from "@/helpers/FileLayerLoader/FileLayerLoader";
import { EXTERNAL_LAYERS_BASE_STYLE } from '@/helpers/external-layers-base-style';
const FILE_LOADER = new FileLayerLoader();
const MAP_TARGET_CRS = "EPSG:4326";

export default {
  components: { UploadFilesInput, CrsSelectionInput },
  data() {
    return {
      selectedFile: null,
      selectedCrs: "EPSG:4326",
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.$store.state.ui.uploadExternalDialogOpen;
      },
      set(val) {
        this.$store.dispatch("ui/SET_UPLOAD_EXTERNAL_LAYER_OPEN", val);
      },
    },
    loading: {
      get() {
        return this.$store.state.ui.loading;
      },
      set(val) {
        this.$store.dispatch("external_layers/SET_UPLOAD_EXTERNAL_LAYER_LOADING", val);
      },
    }
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.selectedCrs = "EPSG:4326";
        this.selectedFile = null;
        this.loading = false;
        this.$nextTick(() => {
          if (this?.$refs?.form) {
            this.$refs.form.resetValidation();
          }
        });
      }
    },
  },
  mounted() {
    FILE_LOADER.on("loading", this.handleFileLoading);
    FILE_LOADER.on("loaded", this.handleFileLoaded);
  },
  beforeDestroy() {
    FILE_LOADER.off("loading", this.handleFileLoading);
    FILE_LOADER.off("loaded", this.handleFileLoaded);
  },
  methods: {
    close() {
      this.isOpen = false;
    },

    handleFileLoading() {
      this.loading = true;
    },

    handleFileLoaded() {
      this.loading = false;
    },

    async upload() {
      if (this.$refs.form.validate()) {
        let fileExtension = this.selectedFile.name.split('.').pop();
        let result = null;
        if (fileExtension == "dxf") {
          result = await FILE_LOADER.loadCadFile(this.selectedFile, {
            sourceSrs: this.selectedCrs,
            targetSrs: MAP_TARGET_CRS,
          });
        } else {
          result = await FILE_LOADER.loadFile(this.selectedFile, fileExtension, {
            sourceSrs: this.selectedCrs,
            targetSrs: MAP_TARGET_CRS,
          });
        }
        if (result) {
          this.$store.dispatch("external_layers/ADD_LAYER", {
            name: this.selectedFile.name,
            _isActive: true,
            style: EXTERNAL_LAYERS_BASE_STYLE,
            geojson: result.data,
          });
          this.close();
        }
      }
    }

  },
};
</script>
