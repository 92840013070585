<template>
  <v-card
    hover
    class="mx-auto mb-1"
    outlined
    dense
    v-if="!!item"
    @click.stop="handleFeatureSelection(item)"
    height="145"
  >
    <v-list-item two-line>
      <v-list-item-content class="text-left">
        <div class="text-caption float-right mb-2">
          <v-chip x-small label outlined color="primary" class="pa-2 text-uppercase">{{
            item.layer_name
          }}</v-chip>
        </div>
        <v-list-item-title class="text-subtitle-2 mb-1" v-html="item.name">
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="!!item.description"
          v-html="item.description"
        ></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar v-if="!!item.image" tile size="80" color="grey">
        <v-img :src="getThumbnailUrl(item)">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                :width="2"
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  props: ["item"],
  computed: {
    package_id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getThumbnailUrl(feature) {
      return `/api/v1/packages/${this.package_id}/documents/${feature.image}/download`;
    },
    handleFeatureSelection(feature) {
      this.$store.dispatch("features/SET_FEATURE_SELECTED", feature);
    },
  },
};
</script>
