export const AVAILABLE_FIXED_SCALES = [
  {
    value: 1000,
    text: "1 : 1000",
    zoom: 17.5,
  },
  {
    value: 2000,
    text: "1 : 2000",
    zoom: 16.8,
  },
  {
    value: 5000,
    text: "1 : 5000",
    zoom: 15.46,
  },
  {
    value: 10000,
    text: "1 : 10 000",
    zoom: 14.41,
  },
  {
    value: 25000,
    text: "1 : 25 000",
    zoom: 13.119,
  },
  {
    value: 50000,
    text: "1 : 50 000",
    zoom: 12.11,
  },
  {
    value: 100000,
    text: "1 : 100 000",
    zoom: 11.12,
  },
  {
    value: 200000,
    text: "1 : 200 000",
    zoom: 10.12,
  },
  {
    value: 500000,
    text: "1 : 500 000",
    zoom: 8.8,
  },
];
