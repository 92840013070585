<template>
  <v-slide-x-reverse-transition>
    <v-navigation-drawer v-show="isOpen" absolute right hide-overlay :width="$vuetify.breakpoint.mobile ? '250' : 400"
      permanent>
      <v-sheet class="text-center fill-height">
        <v-card flat class="fill-height" style="position: relative">
          <v-toolbar flat>
            <v-text-field :disabled="loading" v-model="searchedTerm" single-line hide-details solo flat
              :label="$t('global.search')" width="100%" @keydown.enter="search">
              <template v-slot:append-outer>
                <v-btn small icon plain text color="primary" @click.stop="search">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-btn small icon plain text @click.stop="isOpen = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-toolbar>
          <v-divider />
          <v-card-text style="
              height: calc(100% - 64px - 40px - 5px);
              overflow-y: auto;
              position: relative;
            " class="pa-2">
            <Loading v-if="loading" />
            <v-virtual-scroll v-else-if="items && items.length > 0" height="calc(100%)" item-height="150" :items="items"
              :benched="5">
              <template v-slot:default="{ item }">
                <SearchResult :item="item" />
              </template>
            </v-virtual-scroll>
            <div v-else class="fill-height">
              <v-layout align-center justify-center column fill-height style="
                  position: relative;
                  width: 100%;
                ">
                <v-flex row align-center><v-icon left>mdi-layers-search-outline</v-icon><span class="text-subtitle-2">{{
                  $t('global.no_results_to_show') }}</span></v-flex>
              </v-layout>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="text-center fill-height" style="width: 100%">
              <span v-if="!loading" class="text-caption"
                v-html="$t('global.x_results_found', { count: items.length })"></span>
              <span v-else class="text-caption">{{ $t('global.searching_please_wait') }}</span>
            </div>
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-navigation-drawer>
  </v-slide-x-reverse-transition>
</template>
<script>
import Loading from "@/components/General/Loading";
import SearchResult from "@/components/Search/SearchResult";
export default {
  props: ["mapRef"],
  components: {
    Loading,
    SearchResult,
  },
  data() {
    return {
      searchedTerm: "",
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.$store.state.ui.searchOpen;
      },
      set(val) {
        this.$store.dispatch("ui/SET_SEARCH_OPEN", val);
      },
    },
    package_id() {
      return this.$route.params.id;
    },
    loading() {
      return this.$store.state.features.loadingResults;
    },
    activeLayers() {
      return this.$store.state.layers.activeLayers
        ? this.$store.state.layers.activeLayers
        : [];
    },
    items() {
      return this.$store.state.features.list;
    },
    searchMode() {
      return this.$store.state.ui.searchMode;
    },
    term: {
      get() {
        return this.$store.state.ui.searchTerm
      },
      set(val) {
        let bbox = null;
        if (this.searchMode == 'visible-area' && this.mapRef) {
          bbox = this.mapRef.getBounds();
        }
        this.$store.dispatch("ui/SET_SEARCH_TERM", {
          package_id: this.package_id,
          term: val,
          bbox: bbox,
          active_layers: this.activeLayers,
        });
      }
    },
  },
  watch: {
    isOpen() {
      this.searchedTerm = this.term;
    }
  },
  methods: {
    search() {
      this.term = this.searchedTerm;
    }
  },
};
</script>
