import * as turf from "@turf/turf";

export default class FitZoomToActiveLayersControl {

    constructor(options) {
        this.options = options;
    }

    onAdd(map) {
        this._map = map;
        this._initControl();
        this._registerEvents();
        return this._container;
    }

    _initControl() {
        this._container = document.createElement("div");
        this._container.className =
            "maplibregl-ctrl mapboxgl-ctrl maplibregl-fitzoom maplibregl-ctrl-group mapboxgl-ctrl-group";
        this._container.title = this._map._getUIString(`FitZoom.Title`); 
        this._fitBtn = document.createElement("button");
        this._fitBtn.classList = "maplibregl-fitzoom-button";
        this._fitBtn.type = "button";
        this._fitBtn.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
        </svg>`;
        this._container.appendChild(this._fitBtn);
    }

    _registerEvents() {
        if (this._fitBtn) {
            this._fitBtn.addEventListener("click", this.fitZoom.bind(this));
        }
    }

    fitZoom() {
        if (!this._map) {
            return;
        }
        let layers = this._map.getStyle().layers;
        if (!layers) {
            return;
        }
        layers = layers.filter((l) => l.type !== 'raster');
        let features = [];
        layers.forEach(l => {
            let source = this._map.getSource(l.source);
            features = features.concat(source._data.features);
        });
        let bbox = turf.bbox({
            type: "FeatureCollection",
            features: features,
        });
        try {
            this._map.fitBounds(bbox, {
                padding: 40,
                maxZoom: 18,
            });
        } catch (ex) {
            // Ignore this warning. Probably its the bounding box that is failing
            //console.warn(ex);
        }
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}