<template>
  <v-slide-x-reverse-transition v-if="!!item">
    <v-navigation-drawer v-show="isOpen" absolute right hide-overlay width="450" permanent>
      <v-card flat class="fill-height" style="position: relative" :loading="loading">
        <v-toolbar dense tile flat outlined>
          <v-toolbar-title v-html="item.name" class="text-h6 pl-0">
          </v-toolbar-title>
          <v-spacer />
          <v-btn small icon plain text @click.stop="item = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-1" style="
              height: calc(100% - 64px - 30px);
              overflow-y: auto;
              position: relative;
              overflow-x: hidden;
            ">
          <Loading v-if="loading" />
          <div v-else>
            <v-expansion-panels v-model="expandedPanels" multiple flat accordion v-if="!!details">
              <v-expansion-panel v-for="fieldgroup in details.fields" :key="fieldgroup.id">
                <v-expansion-panel-header v-if="checkNotEmpty(fieldgroup, details)" class="pa-0 primary">
                  <div class="d-flex align-center">
                    <div>
                      <v-icon color="white" class="pl-1">
                        {{ getFieldGroupIcon(fieldgroup) }}
                      </v-icon>
                    </div>
                    <div class="pl-1 white--text text-truncate">{{ fieldgroup.name }}</div>
                  </div>
                  <template v-slot:actions>
                    <v-icon color="white">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-divider class="white"></v-divider>
                <v-expansion-panel-content v-if="checkNotEmpty(fieldgroup, details)">
                  <FieldGroup v-if="isRegularGroup(fieldgroup)" :item="fieldgroup" />
                  <ImagesGroup v-else-if="isImagesGroup(fieldgroup)" :item="fieldgroup" :images="details.images" />
                  <DocumentsGroup v-else-if="isDocumentsGroup(fieldgroup)" :item="fieldgroup"
                    :documents="details.documents" />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="item && item._isPointCluster">
                <v-expansion-panel-header class="pa-0 mb-1 primary">
                  <div class="d-flex align-center">
                    <div>
                      <v-icon color="white" class="pl-1">
                        mdi-dots-grid
                      </v-icon>
                    </div>
                    <div class="pl-1 white--text text-truncate">{{ $t('global.sample_details') }}</div>
                  </div>
                  <template v-slot:actions>
                    <v-icon color="white">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <FeatureClusterGroup :item="item" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="d-flex align-center">
            <v-btn v-if="!layerLoading" small color="primary" :title="$t('global.zoom_in')" :loading="layerLoading" @click.stop="focusFeature()">
              <v-icon x-small>mdi-target</v-icon>
              {{ $t('global.zoom_in') }}
            </v-btn>
            <div v-else>
              <p class="pl-2 mb-0 text-caption">{{ $t('global.loading') }}</p>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
  </v-slide-x-reverse-transition>
</template>
<script>
import Loading from "@/components/General/Loading";
import FieldGroup from "@/components/Features/FieldGroup";
import ImagesGroup from "@/components/Features/ImagesGroup";
import DocumentsGroup from "@/components/Features/DocumentsGroup";
import FeatureClusterGroup from "@/components/Features/FeatureClusterGroup";

import { EventBus } from "@/helpers/event-bus";

export default {
  components: {
    Loading,
    FieldGroup,
    ImagesGroup,
    DocumentsGroup,
    FeatureClusterGroup,
  },
  data() {
    return {
      expandedPanels: [],
    };
  },
  computed: {
    layerLoading() {
      return this.$store.state.layers.loading;
    },
    loading() {
      return this.$store.state.features.loadingFeatureDetails;
    },
    item: {
      get() {
        return this.$store.state.features.featureSelected;
      },
      set(val) {
        return this.$store.dispatch("features/SET_FEATURE_SELECTED", val);
      },
    },
    details() {
      return this.$store.state.features.selectedFeatureDetails;
    },
    isOpen() {
      return this.$store.state.ui.featureDetailsOpen;
    },
  },
  watch: {
    details() {
      this.expandedPanels = [];
      if (this.details) {
        //For some reason field is object for node type points
        let f = this.details.fields;
        if (typeof f === "object") {
          var array = Object.keys(f).map((key) => {
            return f[key];
          });
          this.details.fields = array;
        }
        this.expandedPanels = [...this.details.fields.keys()];

        if (this.item && this.item._isPointCluster) {
          this.expandedPanels.push(this.details.fields.length);
        }
      }
    },
  },
  methods: {
    isRegularGroup(item) {
      return (
        item &&
        (!item.static ||
          item.code == "MAIN_INFO" ||
          item.code == "ADDITIONAL_INFO")
      );
    },
    isImagesGroup(item) {
      return item && item.static && item.code == "IMAGES";
    },
    isDocumentsGroup(item) {
      return item && item.static && item.code == "DOCS";
    },
    setCorrectIcon(item) {
      switch (item) {
        case this.isRegularGroup(item):
          return "mdi-plus";
        case this.isImagesGroup(item):
          return "mdi-plus";
        case this.isDocumentsGroup(item):
          return "mdi-minus";
        default:
          return "mdi-minus";
      }
    },

    getFieldGroupIcon(fieldgroup) {
      if (this.isImagesGroup(fieldgroup)) {
        return "mdi-image-multiple";
      } else if (this.isDocumentsGroup(fieldgroup)) {
        return "mdi-file-document-multiple";
      }
      return "mdi-information-outline";
    },

    focusFeature() {
      EventBus.$emit("focusFeature", { feature: this.item });
    },
    checkNotEmpty(item, details) {
      if (this.isRegularGroup(item)) {
        return this.shouldShow(item);
      } else if (this.isImagesGroup(item)) {
        return !!item && details.images.length > 0;
      } else if (this.isDocumentsGroup(item)) {
        return !!item && details.documents.length > 0;
      }
    },
    shouldShow(item) {
      return (
        !!item &&
        // regular attribute group
        item.attributes &&
        item.attributes.length > 0 &&
        item.attributes.filter((f) => f.value && f.value !== "").length > 0
      );
    },
  },
};
</script>


<style scoped>
.v-expansion-panel-content>>>.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-expansion-panel--active>.v-expansion-panel-header,
.v-expansion-panel-header {
  min-height: 45px;
}
</style>
