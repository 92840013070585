<template>
    <v-card flat width="250" class="pa-2" v-if="isOpen">
        <v-toolbar dense tile flat height="15" class="mb-2">
            <v-spacer />
            <v-btn x-small icon plain text @click.stop="close()" :title="$t('global.close')">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <template v-if="isCurrentTileAServerFeature">
            <v-img v-if="!!url && isCurrentTileAServerFeature" height="150" :src="url" @error="onImgError" class="mb-2">
                <template v-slot:placeholder>
                    <v-skeleton-loader type="image"></v-skeleton-loader>
                </template>
            </v-img>
            <v-card-subtitle class="pa-0 text-overline font-weight-black feature-title mb-1">{{ featureName
            }}</v-card-subtitle>
            <v-card-text class="pa-0 text-body-2 overflow-y-auto feature-description">
                {{ featureDescription }}
            </v-card-text>
            <v-divider inset class="my-2"></v-divider>
            <v-card-actions class="pa-0 ma-0">
                <div v-if="items.length > 1">
                    <v-btn x-small icon plain text :disabled="currentTileIndex == 0" @click.stop="currentTileIndex--">
                        <v-icon small> mdi-chevron-left</v-icon>
                    </v-btn>
                    <span class="text-caption popup-tile-info">{{ currentTileIndex + 1 }} de {{
                        items.length }}</span>
                    <v-btn x-small icon plain text :disabled="currentTileIndex == (items.length - 1)"
                        @click.stop="currentTileIndex++">
                        <v-icon small> mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
                <v-spacer />
                <v-btn color="primary" x-small :title="$t('global.show_more')" @click.stop="openDetails()">{{
                    $t('global.show_more')
                }}</v-btn>
            </v-card-actions>
        </template>
        <template v-else>
            <v-card-text class="pa-0 text-body-2 overflow-y-auto feature-description">
                <div v-if="currentTile?.properties">
                    <div v-for="(prop, key) in currentTile.properties" :key="key" class="d-flex flex-column mb-1">
                        <div class="pr-2">{{ key }}</div>
                        <div class="body-2 font-weight-bold">{{ prop }}</div>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="pa-0 ma-0">
                <div v-if="items.length > 1">
                    <v-btn x-small icon plain text :disabled="currentTileIndex == 0" @click.stop="currentTileIndex--">
                        <v-icon small> mdi-chevron-left</v-icon>
                    </v-btn>
                    <span class="text-caption popup-tile-info">{{ currentTileIndex + 1 }} de {{
                        items.length }}</span>
                    <v-btn x-small icon plain text :disabled="currentTileIndex == (items.length - 1)"
                        @click.stop="currentTileIndex++">
                        <v-icon small> mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-chip x-small label class="text-uppercase font-weight-bold" color="primary" v-if="!!currentTile?.layer?.metadata">{{ getOtherLayersNameByMetadata(currentTile.layer.metadata) }}</v-chip>
            </v-card-actions>
        </template>
    </v-card>
</template>
<script>
export default {
    props: ["value", "items"],
    computed: {
        package_id() {
            return this.$route.params.id;
        },
        shouldShowImage() {
            return !!this.url;
        },
        isCurrentTileAServerFeature() {
            return this.currentTile?.properties?.id && !this.currentTile?.layer?.metadata;
        },
        currentTile() {
            if (this.items) {
                return this.items.at(this.currentTileIndex);
            }
            return null;
        },
        featureName() {
            return this.currentTile?.properties?.name;
        },
        featureDescription() {
            return this.currentTile?.properties?.description;
        },
        isOpen: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
    data() {
        return {
            url: null,
            currentTileIndex: 0,
        };
    },
    watch: {
        isOpen() {
            if (this.isOpen) {
                this.resetPopup();
            }
        },
        currentTileIndex() {
            if (this.currentTile)
                this.url = `/api/v1/packages/${this.package_id}/features/${this.currentTile.properties.id}/thumbnail`;
        },
        items() {
            this.resetPopup();
        },
    },
    methods: {
        onImgError() {
            this.url = null;
        },
        close() {
            this.isOpen = false;
        },
        resetPopup() {
            this.currentTileIndex = 0;
            if (this.currentTile) {
                this.url = `/api/v1/packages/${this.package_id}/features/${this.currentTile.properties.id}/thumbnail`;
            }
        },
        openDetails() {
            this.$store.dispatch("features/SET_FEATURE_SELECTED", {
                ...this.currentTile.properties
            });
        },

        getOtherLayersNameByMetadata(metadata) {
            return this.$t('global.' + metadata["source:type"]);
        }
    }
}
</script>
<style scoped>
.feature-title {
    font-size: 0.6rem !important;
    line-height: 0.7rem !important
}

.feature-description {
    max-height: 150px;
    font-size: 0.7rem !important;
}

.popup-tile-info {
    font-size: 0.6rem !important;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: #000000;
}

@media only screen and (max-width: 800px) {
    .feature-description {
        max-height: 75px;
    }
}
</style>
<style>
.mapboxgl-popup-close-button,
.maplibregl-popup-close-button {
    display: none !important;
}

.maplibregl-popup-content {
    padding: 0 !important;
}
</style>