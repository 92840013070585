<template>
  <v-card v-if="shouldShow" class="pa-0 ma-0" flat>
    <v-list dense>
      <template v-for="(item, index) in documents">
        <v-list-item
          :key="item.id"
        >
          <v-list-item-content class="text-start">
            <v-list-item-title
              class="text-subtitle-2"
              v-text="item.name"
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :href="getDocumentLink(item)"
                    target="_blank"
                    icon
                    link
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Descarregar</span>
              </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          :key="index"
          v-if="isLastDivider(documents, index)"
        ></v-divider>
      </template>
    </v-list>
  </v-card>
  <v-card flat v-else>
    <v-card-text class="pt-4">
        <div class="subtitle-2">(sem documentos para mostrar)</div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {},
  props: ["item", "documents"],
  data: () => ({
    items: [
      { header: "Today" },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle:
          '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Birthday gift",
        subtitle:
          '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
    ],
  }),
  computed: {
    package_id() {
      return this.$route.params.id;
    },
    shouldShow() {
      return !!this.item && this.documents.length > 0;
    },
  },
  methods: {
    getDocumentLink(document) {
      return `/api/v1/packages/${this.package_id}/documents/${document.id}/download`;
    },
    isLastDivider(documents, index) {
      return documents.length - 1 == index ? false : true;
    },
  },
};
</script>